import React, { useContext } from "react";
import Card, { CardBody, CardHeader } from "./bootstrap/Card";
import Avatar from "./Avatar";
import { getColorNameWithIndex } from "../common/data/enumColors";
import Icon from "./icon/Icon";
import useDarkMode from "../hooks/useDarkMode";
import { getFirstLetter } from "../helpers/helpers";
import Alert, { AlertHeading } from "./bootstrap/Alert";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "./bootstrap/Modal";
import dayjs from "dayjs";
import { AppSumoContext } from "../contexts/AppSumoContext";
import Button from "./bootstrap/Button";

export const camelCaseToWords = (s:string) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getIconByFieldName = (key:string) => {
    if (String(key).toLowerCase().includes("created_at")) {
        return 'CalendarToday'
    }
    if (String(key).toLowerCase().includes("name")) {
        return 'Person';
    }
    if (String(key).toLowerCase().includes("email")) {
        return 'Mail';
    }
    if (String(key).toLowerCase().includes("phone")) {
        return 'Phone';
    }
    if (String(key).toLowerCase().includes("address")) {
        return 'Place';
    }
    if (String(key).toLowerCase().includes("country")) {
        return 'Flag'
    }
    if (String(key).toLowerCase().includes("city")) {
        return 'LocationCity'
    }
    if (String(key).toLowerCase().includes("title")) {
        return 'Work'
    }
    if (String(key).toLowerCase().includes("company")) {
        return 'HomeWork'
    }
    if (String(key).toLowerCase().includes("birth")) {
        return 'Cake'
    }
    if (String(key).toLowerCase().includes("car")) {
        return 'DirectionsCar'
    }
    if (String(key).toLowerCase().includes("gender")) {
        return 'Transgender'
    }
    if (String(key).toLowerCase().includes("budget")) {
        return 'AttachMoney'
    }
  
    return 'Circle';
}

const AppSumoWelcomeModal = (props: any) => {

    const { showAppSumoWelcomeModal, setShowAppSumoWelcomeModal } = useContext(AppSumoContext);
	const { darkModeStatus } = useDarkMode();

    return (
        <Modal
            setIsOpen={setShowAppSumoWelcomeModal}
            isOpen={showAppSumoWelcomeModal}
            titleId='contact-view-modal'
            className="!my-0"
            contentClassName="!bg-transparent !shadow-none !h-[100%]"
            dialogClassName="!h-[100%] !my-0"
        >
            <div className="hidden"></div>
            <ModalBody className='p-0 lg:p-inherit h-100 d-flex justify-center items-center align-items-center !bg-transparent w-full'>
                <Card className='shadow-3d-primary relative w-[500px] max-w-full'>
                    <CardHeader className="!p-0 overflow-hidden">
                        <img src="images/appsumo-banner.webp" className="w-full object-cover object-position-center object-center h-[150px]" />
                    </CardHeader>
                    <CardBody className="max-h-[90vh] overflow-y-auto lg:py-16 lg:pb-12 flex flex-col gap-10">
                        <h1 className="text-center text-4xl font-bold">Thank you for your purchase!</h1>
                        <p className="text-center">You are just one step away from your lifetime Standard Plan.<br/><br/>Just click on the "Continue" button and login or create a new account. Your Standard Plan will be activated right away!</p>
                        <div className="text-center mt-2">
                            <Button color="primary" size="lg" onClick={() => setShowAppSumoWelcomeModal(false)}>Continue</Button>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default AppSumoWelcomeModal;