import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";

interface ICardProps {
    formik: any,
    className?: string,
    isEdit?: boolean
}

const CardCompanyDetails: FC<ICardProps> = ({
    formik, 
    className,
    isEdit = false 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='MapsHomeWork' iconColor='info'>
                    <CardTitle>Company Details</CardTitle>
                </CardLabel>
                <CardActions>
                    <Badge color={'warning'}>
                        Required
                    </Badge>
                </CardActions>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className={isEdit ? 'col-md-6' : 'col-md-12'}>
                        <FormGroup
                            id='company_name'
                            label='Company Name'
                            isFloating>
                            <Input
                                placeholder='Company Name'
                                onChange={(e:any) => {
                                    formik.handleChange(e);
                                    formik.setFieldValue('assistant_name', e.target.value);
                                }}
                                value={formik.values.company_name}
                                onBlur={formik.handleBlur}
                                invalidFeedback={formik.errors.company_name as any}
                                isValid={formik.isValid}
                                isTouched={formik.touched.company_name as any}
                                required
                            />
                        </FormGroup>
                    </div>
                    <div className={isEdit ? 'col-md-6' : 'col-md-12'}>
                        <FormGroup
                            id='website'
                            label='Company Website'
                            isFloating>
                            {/* <InputGroup> */}
                                {/* <InputGroupText>https://</InputGroupText> */}
                                <Input
                                    placeholder='Company Website'
                                    onChange={formik.handleChange}
                                    value={formik.values.website}
                                    onBlur={formik.handleBlur}
                                    invalidFeedback={formik.errors.website as any}
                                    // validFeedback="Looks good!"
                                    isValid={formik.isValid}
                                    isTouched={formik.touched.website as any}
                                    required
                                />
                            {/* </InputGroup> */}
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardCompanyDetails;