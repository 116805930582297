import { useState } from "react";
import Button from "../../../components/bootstrap/Button"
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal"
import Api from "../../../services/Api";
import showNotification from "../../../components/extras/showNotification";
import { useNavigate } from "react-router-dom";


const DeleteChatbroModal = (props:any) => {

    const {
        isOpenModalDeleteChatbro,
        setIsOpenModalDeleteChatbro,
        formik
    } = props;

    const [ isLoading, setIsLoading ] = useState(false);
    const navigate = useNavigate();

    const deleteChatbro = () => {
        Api.delete(`/chatbots/${formik?.values?.id}`).then(() => {
            showNotification("Success", "Your Chatbro has been successfully deleted", "success");
            navigate('/chatbots?update');
            setIsOpenModalDeleteChatbro(false);
        }).catch((err) => {
            showNotification('Error', err?.message, 'danger');
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <Modal
            isOpen={isOpenModalDeleteChatbro}
            setIsOpen={setIsOpenModalDeleteChatbro}
            titleId='deleteChatbroModal'
            isCentered={true}
        >
            <ModalHeader setIsOpen={setIsOpenModalDeleteChatbro}>
                <ModalTitle id='deleteChatbroModal' className="font-bold">Delete Chatbro</ModalTitle>
            </ModalHeader>
            <ModalBody>Are you sure you want to delete your Chatbro? This action can't be undone.</ModalBody>
            <ModalFooter>
                <Button
                    color='info'
                    isOutline
                    className='border-0'
                    onClick={() => setIsOpenModalDeleteChatbro(false)}>
                    Close
                </Button>
                <Button onClick={deleteChatbro} color='danger' icon='Delete'>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteChatbroModal;