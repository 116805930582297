import React, { FC, useContext, useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Chart from '../../../components/extras/Chart';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Badge from '../../../components/bootstrap/Badge';
import { hasActiveSubscription, priceFormat, removeProtocol } from '../../../helpers/helpers';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import Avatar from '../../../components/Avatar';
import Api from '../../../services/Api';
import dayjs from 'dayjs';
import { formatSeries } from '../../dashboard/Dashboard';
import Spinner from '../../../components/bootstrap/Spinner';
import { SessionContext } from '../../../contexts/SessionContext';

interface IGridChatbotItemProps {
	id: string | number;
	name: string;
	company_name: string;
	website: string;
	socialNetworks: any;
	color: string;
	active: boolean;
	editAction: any;
	deleteAction: any;
}
const GridChatbotItem: FC<IGridChatbotItemProps> = ({
	id,
	name,
	company_name,
	website,
	socialNetworks,
	color,
	active,
	editAction,
	deleteAction,
}) => {

	const { user } = useContext<any>(SessionContext);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [ series, setSeries ] = useState<any>([1, 1, 1, 1, 1]);
	const [ isLoadingSeries, setIsLoadingSeries ] = useState(true);

	const dateStart = dayjs().subtract(7, 'days').toDate();
	const dateEnd = dayjs().toDate();

	const integration = socialNetworks?.filter((e:any) => e?.type == "web")?.[0];
	const img = integration?.image?.url || "/images/avatar-boy.png";

	const dummyOptions: ApexOptions = {
		colors: [String(process.env.REACT_APP_SUCCESS_COLOR)],
		chart: {
			type: 'line',
			width: 100,
			height: 35,
			sparkline: {
				enabled: true,
			},
		},
		tooltip: {
			theme: 'dark',
			fixed: {
				enabled: false,
			},
			x: {
				show: false,
			},
			y: {
				title: {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					formatter(seriesName: string) {
						return '';
					},
				},
			},
		},
		stroke: {
			curve: 'smooth',
			width: 2,
		},
	};

	useEffect(() => {
		setIsLoadingSeries(true);
		Api.get(`/conversations?group_by=created_at&filter[created_at_after]=${dateStart.toISOString()}&filter[created_at_before]=${dateEnd.toISOString()}&filter[socialNetwork.chatbot_id]=${id}`).then((_conversations) => {
			setSeries(formatSeries(_conversations?.data, dateStart, dateEnd));
		}).finally(() => {
			setIsLoadingSeries(false);
		});
	}, [id]);

	return (
		<Card>
			<CardHeader className='w-full'>
				<CardLabel className='shrink-0 flex-1 w-full overflow-hidden'>
					<CardTitle tag='div' className='h5 text-overflow-ellipsis ellipsis overflow-hidden whitespace-nowrap w-full flex-1 shrink-0'>
						{company_name || name}{' '}
					</CardTitle>
					<CardSubTitle tag='div' className='h6 shrink-0'>
						{removeProtocol(website)}
					</CardSubTitle>
				</CardLabel>
				<CardActions className='shrink-0'>
					<Badge color={active && hasActiveSubscription(user) ? 'success' : 'danger'} isLight className='ms-2 text-sm px-3'>
						{active && hasActiveSubscription(user) ? 'Active' : 'Inactive'}
					</Badge>
					{/* <Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								icon='MoreHoriz'
								color={themeStatus}
								shadow='default'
								aria-label='Edit'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							<DropdownItem>
								<Button icon='Edit' onClick={() => editAction()}>
									Edit
								</Button>
							</DropdownItem>
							<DropdownItem>
								<Button
									icon='FileCopy'
									onClick={() => {
										showNotification(
											<span className='d-flex align-items-center'>
												<Icon icon='Info' size='lg' className='me-1' />
												<span>{name} duplicated.</span>
											</span>,
											`A copy of the ${name} product was created.`,
										);
									}}>
									Duplicate
								</Button>
							</DropdownItem>
							<DropdownItem isDivider />
							<DropdownItem>
								<Button icon='Delete' onClick={() => deleteAction()}>
									Delete
								</Button>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown> */}
				</CardActions>
			</CardHeader>
			<CardBody>
				<Avatar
					src={img}
					size={128}
					className='mx-auto d-block img-fluid mb-8 rounded-full !object-cover !w-[128px] !h-[128px]'
				/>
				<div className='row align-items-center'>
					<div className='col'>Weekly conversations</div>
					<div className='col-auto'>
						{isLoadingSeries
							? <Spinner className='opacity-[0.5]' isGrow isSmall />
							: <Chart
								series={[{ data: series.series }]}
								options={dummyOptions}
								type={dummyOptions.chart?.type}
								height={dummyOptions.chart?.height}
								width={dummyOptions.chart?.width}
							/>
						}
					</div>
				</div>
			</CardBody>
			<CardFooter className='shadow-3d-container'>
				<Button
					color='dark'
					className={`view-chatbot-button w-100 mb-4 shadow-3d-up-hover shadow-3d-${
						darkModeStatus ? 'light' : 'dark'
					}`}
					size='lg'
					tag='a'
					to={`../${demoPagesMenu.chatbots.path}/${id}`}
				>
					View Chatbro
				</Button>
			</CardFooter>
		</Card>
	);
};

export default GridChatbotItem;
