import React, { lazy } from 'react';
import { authPages, dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu, previewPages } from '../menu';
import Login from '../pages/presentation/auth/Login';
import Dashboard from '../pages/dashboard/Dashboard';
import Chatbots from '../pages/chatbots/Chatbots';
import Contacts from '../pages/contacts/Contacts';
import Conversations from '../pages/conversations/Conversations';
import LoginWaitlist from '../pages/presentation/auth/LoginWaitlist';
import ChatbotPreview from '../pages/chatbot_preview/ChatbotPreview';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: demoPagesMenu.conversations.path,
		element: <Conversations />,
	},
	{
		path: demoPagesMenu.contacts.path,
		element: <Contacts />,
	},
	{
		path: demoPagesMenu.chatbots.path_form,
		element: <Chatbots />,
	},
	{
		path: demoPagesMenu.chatbots.path,
		element: <Chatbots />,
	},
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <Dashboard />,
	},
	{
		path: authPages.login.path,
		element: <Login />,
	},
	{ 
		path: previewPages.preview.path, 
		element: <ChatbotPreview /> 
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	}

];
const contents = [...presentation];

export default contents;
