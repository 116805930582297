import React, { FC, useContext, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, leadFieldOptions, toneOptions } from "./NewChatbotModal";
import Icon from "../../../components/icon/Icon";
import Alert from "../../../components/bootstrap/Alert";
import PrismCode from "../../../components/extras/PrismCode";
import { createUseStyles } from "react-jss";
import Button from "../../../components/bootstrap/Button";
import { useClipboard } from "use-clipboard-copy";
import showNotification from "../../../components/extras/showNotification";
import { SessionContext } from "../../../contexts/SessionContext";
import { PricingModalContext } from "../../../contexts/PricingModalContext";

interface ICardProps {
    formik: any,
    className?: string
}

const useStyles = createUseStyles({
	// stylelint-disable-next-line selector-type-no-unknown
	prism: {
		padding: '0 !important',
		margin: '0 !important',
		background: 'transparent !important',
		borderRadius: '0 !important',

		// stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
		'& > code': {
			// stylelint-disable-next-line scale-unlimited/declaration-strict-value
			color: '#2e3f96 !important',
			fontSize: '0.875rem !important',
		},
	},
});

const CardWebIntegration: FC<ICardProps> = ({
    formik, 
    className 
}) => {
    
    const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);

	const clipboard = useClipboard();
    const classes = useStyles();
	const CODE = `
    <script>
(function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.id='chatbro_script'
    s1.async=true;
    s1.src='https://widget.chatbros.ai/include.js?id=${formik?.values?.external_id}';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
})();
</script>`;

    const handleOnCopy = () => {
        clipboard.copy(CODE);
        showNotification(
            <span className='d-flex align-items-center'>
                <Icon icon='ContentCopy' size='lg' className='me-1' />
                <span>Snipped copied!</span>
            </span>,
            'The snippet has been successfully copied to the clipboard',
            'success'
        );
    }

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='Code' iconColor='primary'>
                    <CardTitle>Web Integration Snippet</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-12'>
                        <p className="mb-4">Copy and paste the following code snippet on the bottom of the head tag of the website you want to integrate.</p>
                        <div className="relative">
                            <Alert
                                color='info'
                                isLight
                                shadow='md'
                                borderWidth={0}
                                icon='CustomReact'
                                className={`flex-nowrap overflow-y-auto w-100 relative`}>
                                    <PrismCode code={CODE} language='html' className={classes.prism} />
                            </Alert>
                            <Button
                                className='h-100 !absolute right-4 bottom-4 !h-10'
                                color='info'
                                isLight
                                icon='ContentCopy'
                                onClick={handleOnCopy}>
                                Copy
                            </Button>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardWebIntegration;