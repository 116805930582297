import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Chart, { IChartOptions } from '../../../components/extras/Chart';

interface ISalePerTab {
	[key: string]: 'Last 30 days';
}

interface IConversationsChartProps {
    conversations?: any;
    visits?: any;
}

const ConversationsChart: FC<IConversationsChartProps> = ({
	conversations,
	visits
}) => {

	const chartOptions = {
		chart: {
			height: 300,
			type: 'area',
			toolbar: {
				show: false,
			},
		},
		colors: [process.env.REACT_APP_SUCCESS_COLOR, process.env.REACT_APP_INFO_COLOR],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'smooth',
		},
		tooltip: {
			x: {
				format: 'dd/MM/yy HH:mm',
			},
			theme: 'dark',
		},
		fill: {
			type: 'gradient',
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 100],
			},
		},
	};

	function getDate(day: number) {
		const arr = [];
		for (let i = 0; i < day; i += 1) {
			arr.push(
				dayjs()
					.add(-1 * i, 'day')
					.format('ll'),
			);
		}
		return arr.reverse();
	}

	const DUMMY_DATA: { [key: string]: IChartOptions } = {
		MONTH: {
			series: [
				{
					name: 'Visits',
					data: visits?.series,
				},
				{
					name: 'Conversations',
					data: conversations?.series,
				},
			],
			// @ts-ignore
			options: {
				...chartOptions,
				xaxis: {
					categories: conversations?.labels,
				},
			},
		},
	};
	const [state, setState] = useState<IChartOptions>({
		series: DUMMY_DATA.MONTH.series,
		options: DUMMY_DATA.MONTH.options,
	});

	const SALE_PER_TAB: { [key: string]: ISalePerTab['key'] } = {
		MONTH: 'Last 30 days',
	};
	const [activeSalePerTab, setActiveSalePerTab] = useState<ISalePerTab['key']>(
		SALE_PER_TAB.MONTH,
	);

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle tag='div' className='h5'>
						Conversations/visits per day
					</CardTitle>
					<CardSubTitle>
						Number of visits and conversations of the last 30 days
					</CardSubTitle>
				</CardLabel>
				<CardActions>
					{/* <Button
						color='info'
						onClick={() => {
							setActiveSalePerTab(SALE_PER_TAB.DAY);
							setState({
								series: DUMMY_DATA.DAY.series,
								options: DUMMY_DATA.DAY.options,
							});
						}}
						isLink={activeSalePerTab !== SALE_PER_TAB.DAY}
						isLight={activeSalePerTab === SALE_PER_TAB.DAY}>
						Day
					</Button>
					<Button
						color='info'
						onClick={() => {
							setActiveSalePerTab(SALE_PER_TAB.WEEK);
							setState({
								series: DUMMY_DATA.WEEK.series,
								options: DUMMY_DATA.WEEK.options,
							});
						}}
						isLink={activeSalePerTab !== SALE_PER_TAB.WEEK}
						isLight={activeSalePerTab === SALE_PER_TAB.WEEK}>
						Week
					</Button> */}
					<Button
						color='info'
						onClick={() => {
							setActiveSalePerTab(SALE_PER_TAB.MONTH);
							setState({
								series: DUMMY_DATA.MONTH.series,
								options: DUMMY_DATA.MONTH.options,
							});
						}}
						isLink={activeSalePerTab !== SALE_PER_TAB.MONTH}
						isLight={activeSalePerTab === SALE_PER_TAB.MONTH}>
						Last 30 Days
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody>
				<Chart
					series={state.series}
					options={state.options}
					type={state.options.chart?.type}
					height={state.options.chart?.height}
				/>
			</CardBody>
		</Card>
	);
};

export default ConversationsChart;
