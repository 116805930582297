import React, { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Page from '../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Chat, { ChatAvatar, ChatGroup, ChatListItem } from '../../components/Chat';
import InputGroup from '../../components/bootstrap/forms/InputGroup';
import Textarea from '../../components/bootstrap/forms/Textarea';
import USERS, { IUserProps } from '../../common/data/userDummyData';
import Icon from '../../components/icon/Icon';
import ThemeContext from '../../contexts/themeContext';
import { demoPagesMenu } from '../../menu';
import { getColorNameWithIndex } from '../../common/data/enumColors';
import Input from '../../components/bootstrap/forms/Input';
import Api from '../../services/Api';
import showNotification from '../../components/extras/showNotification';
import Spinner from '../../components/bootstrap/Spinner';
import Badge from '../../components/bootstrap/Badge';
import useDarkMode from '../../hooks/useDarkMode';
import EmojiPicker from 'emoji-picker-react';
import { useClickAway } from 'react-use';
import axios from 'axios';
const { getCode, getName } = require('country-list');

export interface IMessages {
	id: number;
	messages: { id: number; message: string }[];
	user: IUserProps;
	isReply?: boolean;
}

const Conversations = () => {

	const [users, setUsers] = useState([]);
	const [activeTab, setActiveTab] = useState<any>();
	const { mobileDesign } = useContext(ThemeContext);
	const [listShow, setListShow] = useState<boolean>(true);
	const [messages, setMessages] = useState<any>([]); 
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingMessages, setIsLoadingMessages] = useState(false);
	const [nowTimestamp, setNowTimestamp] = useState(new Date().getTime());
	const [lastMessageID, setLastMessageID] = useState<number>();
	const [totalUnreadMessages, setTotalUnreadMessages] = useState<number>(0);
	const [message, setMessage] = useState("");
	const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false);
	const [isLoadingToggleAssistantMessageEnable, setIsLoadingToggleAssistantMessageEnable] = useState(false);
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);
	const { darkModeStatus } = useDarkMode();
	const emojiPickerRef = useRef<any>();
	const inputFileRef = useRef<any>();

	const onlineAllowedTimestamp = 1000 * 60 * 2; // 2 mins without receiving last_seen_at in widget

	const selectedUser:any = users?.filter((e:any) => e.id == activeTab)?.[0] || {};

	const sendMessage = (_message?:any) => {
		const sendMessage = _message || message;
		Api.post(`/messages`, {}, { conversation_id: activeTab, message: sendMessage }).then(({ data: message }) => {
			setLastMessageID(message.id);
			updateLastReadAt(activeTab);
		});
		let _messages = [...[{ 
			message: sendMessage, 
			sender: 'assistant', 
			created_at: new Date(),
			conversation: messages?.[0]?.conversation
		}], ...messages?.map((e:any) => e.messages)?.flat()];
		let _newMessages = [];
		let lastSender;
		for (let i = 0; i < _messages.length; i++) {
			if (lastSender != _messages[i]?.sender) {
				_newMessages.push({ ..._messages[i], messages: [_messages[i]]});
			} else {
				_newMessages?.[_newMessages?.length - 1]?.messages?.unshift(_messages[i]);
			}
			lastSender = _messages[i]?.sender;
		}
		setMessages(_newMessages);
		setMessage("");
	}

	const handleOnClickEmoji = (emoji:any) => {
		setMessage(`${message}${emoji}`);
	}

	const getUsers = () => {
		!users?.length && setIsLoading(true);
		Api.get(`/conversations?include=lastUserMessage,unreadMessagesCount,messagesCount,image,socialNetwork.chatbot&sort=lastMessage`).then(({ data: _users }) => {
			setUsers(_users);
		}).catch((err) => {
			// showNotification('Error', `Error loading conversations. ${err?.message}`, 'danger');
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const getMessages = (conversation_id?: any) => {
		if (conversation_id) {
			!messages?.length && setIsLoadingMessages(true);
			Api.get(`/messages?sort=-id&include=conversation.socialNetwork.image&filter[conversation_id]=${conversation_id}&${!!lastMessageID ? `&filter[id-gt]=${lastMessageID}` : ``}`).then(({ data: _messages }) => {
				if (_messages?.length) {
					setLastMessageID(_messages?.[0]?.id);
					_messages = [..._messages, ...messages?.map((e:any) => e.messages)?.flat()];
					let _newMessages = [];
					let lastSender;
					for (let i = 0; i < _messages.length; i++) {
						if (lastSender != _messages[i]?.sender) {
							_newMessages.push({ ..._messages[i], messages: [_messages[i]]});
						} else {
							_newMessages?.[_newMessages?.length - 1]?.messages?.unshift(_messages[i]);
						}
						lastSender = _messages[i]?.sender;
					}
					setMessages(_newMessages);
				}
			}).catch((err) => {
				// showNotification('Error', `Error loading messages. ${err?.message}`, 'danger');
			}).finally(() => {
				setIsLoadingMessages(false);
			});
		}
	}

	const getListShow = (id: any) => {
		if (activeTab != id) {
			setMessages([]);
			setLastMessageID(undefined);
			setActiveTab(id);
			updateLastReadAt(id);
			if (mobileDesign) {
				setListShow(false);
			}
		}
	}

	const updateLastReadAt = (id:any) => {
		Api.put(`/conversations/${id}`, {}, { update_last_read_at: true }).then((response) => response).catch((err) => err);
		let _newUsers:any = users;
		_newUsers[_newUsers?.findIndex((e:any) => e?.id == id)].unreadMessages_count = 0;
		setUsers(_newUsers);
	}

    const goToBottom = () => {
        var containerDiv:any = document.getElementById("messagebox");
        if (containerDiv) {
            setTimeout(() => {
                containerDiv.scrollTop = containerDiv.scrollHeight;
            }, 10);
        }
    }

	const isOnline = (user:any) => {
		return nowTimestamp - new Date(user?.last_seen_at).getTime() < onlineAllowedTimestamp;
	}
	
	const countTotalUnreadMessages = () => {
		setTotalUnreadMessages(users?.filter((e:any) => e.id != activeTab).reduce((n, { unreadMessages_count }) => n + unreadMessages_count, 0));
	}

	const toggleAssistantMessageEnabled = async (value:boolean) => {
		setIsLoadingToggleAssistantMessageEnable(true);
		await Api.put(`/conversations/${activeTab}`, {}, { assistant_message_enabled: value }).then((response) => response).catch((err) => err);
		let _newUsers:any = users;
		_newUsers[_newUsers?.findIndex((e:any) => e?.id == activeTab)].assistant_message_enabled = value;
		setUsers(_newUsers);
		setIsLoadingToggleAssistantMessageEnable(false);
	}

	const uploadConversationFiles = async () => {
		function readFileAsync(file:File) {
			return new Promise((resolve, reject) => {
				// const file = e.target.files[0];
				if (!file) {
					return;
				}
				const reader = new FileReader() as any;
				reader.onload = () => {
					resolve(`data:${file.type};base64,${btoa(reader.result)}`);
				};
				reader.onerror = reject;
				reader.readAsBinaryString(file);
			});
		}
		if (!!inputFileRef.current.files.length) {
			setIsLoadingUpload(true);
			try {
				let images = [];
				for (var i = 0; i < inputFileRef.current.files.length; i++) {
					const newImage:any = await readFileAsync(inputFileRef.current.files[i]);
					if (newImage) {
						const blob = await fetch(newImage).then(res => res.blob());
						const imageType = newImage?.substring("data:image/".length, newImage?.indexOf(";base64"));
						const name = `image_${activeTab}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}.${imageType}`;
						const file = new File([blob], name, { type: `image/${imageType}` });
						const { public_uri, aws_uri } = await Api.post(`/conversations/${activeTab}/images`, {}, { filename: name, type: "image", visibility: "public" }).then((response) => { return { public_uri: response.data.url, aws_uri: response.data.presigned_url } });
						await axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {});
						images.push(public_uri);
					}
				}
				sendMessage(images.join("\n"));
			} catch(err) {}
			setIsLoadingUpload(false);
		}
	}

    useClickAway(emojiPickerRef, () => {
        if (emojiPickerRef) setIsOpenEmojiPicker(false);
    });

	useEffect(() => {
		getMessages(activeTab);
	}, [activeTab]);

	useEffect(() => {
		const interval = setInterval(() => {
			getMessages(activeTab);
		}, 15000);
		return () => clearInterval(interval);
	}, [lastMessageID]);

	useEffect(() => {
		countTotalUnreadMessages();
		const interval = setInterval(() => {
			getUsers();
		}, 15000);
		return () => clearInterval(interval);
	}, [users]);

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setNowTimestamp(new Date().getTime());
		}, 1000);
		return () => clearTimeout(timeout);
	}, [nowTimestamp]);
	
	useEffect(() => {
		goToBottom();
	}, [messages]);

	return (
		<PageWrapper title={demoPagesMenu.conversations.text}>
			{/* <SubHeader className={`transition-all overflow-hidden ${!totalUnreadMessages && `!p-0 !m-0 !h-0 !opacity-0 !min-h-0`}`}>
				<SubHeaderLeft>
					<span className='flex items-center'>
						<Icon icon='Info' className='me-2' size='2x' color='danger' />
						<span className='text-muted'>
							You have <Icon icon='Chat5' color='danger' className='mx-1' size='lg' />{' '}
							{totalUnreadMessages} unread messages.
						</span>
					</span>
				</SubHeaderLeft>
			</SubHeader> */}
			<Page>
				<div className='row h-100'>
					{(listShow || !mobileDesign) && (
						<div className='col-lg-4 col-md-6'>
							<Card stretch className='overflow-hidden'>
								{isLoading 
									? <div className='w-full h-full flex items-center justify-center'><Spinner isGrow/></div>
									: <CardBody isScrollable className='p-0'>
										<Card shadow='none' className='mb-0'>
											<CardHeader className='sticky-top'>
												<CardLabel icon='AccountCircle' iconColor='success'>
													<CardTitle tag='div' className='h5'>
														Online
													</CardTitle>
													<CardSubTitle tag='div' className='h6'>
														{users?.filter((u:any) => isOnline(u))?.length || 0} users
													</CardSubTitle>
												</CardLabel>
											</CardHeader>
											<CardBody className='border-bottom border-light'>
												<div className='row'>
													{!users?.filter((u:any) => isOnline(u))?.length && <>
														<span className='text-2xl text-gray-300 font-semibold col-12'>There are no online users</span>
													</>}
													{users?.filter((u:any) => isOnline(u))?.map((item:any, key:any) => (
														<ChatListItem
															key={key}
															onClick={() => getListShow(item?.id)}
															isActive={activeTab === item?.id}
															src={item?.image?.url}
															name={item?.external_name || item?.leads?.name}
															isOnline={isOnline(item)}
															color={getColorNameWithIndex(item?.id)}
															lastSeenTime={dayjs(item?.last_seen_at).fromNow()}
															latestMessage={item?.lastUserMessage?.message}
															unreadMessage={activeTab === item?.id ? 0 : item?.unreadMessages_count}
															user={item}
														/>
													))}
												</div>
											</CardBody>
										</Card>
										<Card shadow='none' className='mb-0'>
											<CardHeader className='sticky-top'>
												<CardLabel icon='AccountCircle' iconColor='danger'>
													<CardTitle tag='div' className='h5'>
														Offline
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													{!users?.filter((u:any) => !isOnline(u))?.length && <>
														<span className='text-2xl text-gray-300 font-semibold col-12'>There are no offline users</span>
													</>}
													{users?.filter((u:any) => !isOnline(u))?.map((item:any, key:any) => (
														<ChatListItem
															key={key}
															onClick={() => getListShow(item?.id)}
															isActive={activeTab === item?.id}
															src={item?.image?.url}
															name={item?.external_name || item?.leads?.name}
															isOnline={isOnline(item)}
															color={getColorNameWithIndex(item?.id)}
															lastSeenTime={dayjs(item?.last_seen_at).fromNow()}
															latestMessage={item?.lastUserMessage?.message}
															unreadMessage={activeTab === item?.id ? 0 : item?.unreadMessages_count}
															user={item}
														/>
													))}
												</div>
											</CardBody>
										</Card>
									</CardBody>
								}
							</Card>
						</div>
					)}
					{(!listShow || !mobileDesign) && (
						<div className='col-lg-8 col-md-6'>
							{isLoadingMessages 
								? <Card stretch><div className='w-full h-full flex items-center justify-center'><Spinner isGrow /></div></Card>
								: activeTab && !!messages?.length
									? <Card stretch>
										<CardHeader className='border-bottom border-light !items-center'>
											<CardActions>
												<div className='d-flex align-items-center'>
													<ChatAvatar
														src={selectedUser?.image?.url}
														isOnline={isOnline(selectedUser)}
														unreadMessage={0}
														color={getColorNameWithIndex(selectedUser?.id)}
														className='me-3'
														user={selectedUser}
														size={55}
													/>
													<div className='fw-bold flex flex-col gap-1.5 text-lg'>
														{selectedUser?.external_name || selectedUser?.leads?.name || selectedUser?.leads?.email || selectedUser?.metadata?.ip || selectedUser?.metadata?.country_iso_code || "Unknown"} (#{selectedUser?.id})
														<br />
														<div className='flex items-center gap-2.5 text-base'>
															<Badge color={isOnline(selectedUser) ? 'success' : 'danger'} isLight={!isOnline(selectedUser)}>{isOnline(selectedUser) ? 'Online' : 'Offline'}</Badge>
															<div className='font-normal flex items-center gap-1.5 text-sm'>
																<Icon icon='CalendarToday' color='info' />
																{dayjs(selectedUser?.created_at).toDate().toLocaleString()}
															</div>
														</div>
													</div>
												</div>
											</CardActions>
											{(!!selectedUser?.metadata?.website || !!selectedUser?.socialNetwork?.chatbot?.website) && 
												<CardTitle className='flex flex-col items-end gap-1.5 !font-normal !m-0'>
													<div className='flex items-center gap-1 font-semibold'>
														<Icon icon='Public' />
														<span>{selectedUser?.metadata?.website || selectedUser?.socialNetwork?.chatbot?.website}</span>
													</div>
													{!!selectedUser?.metadata?.country_iso_code && <div className='flex items-center gap-1.5'>
														<img src={`/images/flags/${selectedUser?.metadata?.country_iso_code?.toLowerCase()}.png`} className='h-4 !rounded-sm' />
														{!!selectedUser?.metadata?.city && `${selectedUser?.metadata?.city}, `}{getName(selectedUser?.metadata?.country_iso_code || "")}
													</div>}
												</CardTitle>
											}
										</CardHeader>
										<CardBody id='messagebox' isScrollable>
											<Chat>
												{!!activeTab && !!messages?.length && 
													// @ts-ignore
													messages.map((msg, key) => (
														<ChatGroup
															key={key}
															messages={msg.messages}
															user={msg.sender == 'user' ? selectedUser : msg?.conversation?.socialNetwork}
															isReply={msg.sender != 'user'}
															isOnline={isOnline(selectedUser)}
															color={getColorNameWithIndex(selectedUser?.id)}
														/>
													))}
											</Chat>
										</CardBody>
										{/* {isOnline(selectedUser) &&  */}
											<CardFooter className='d-block border-top border-light relative !py-0 !pb-0.5'>
												{selectedUser?.assistant_message_enabled 
													? <>
														<div className='w-full flex justify-between items-center h-full gap-2'>
															<p className='flex items-center gap-2'>
																<span className='font-semibold text-gray-400'>AI Auto-Response is</span>
																<Badge color='success'>Enabled</Badge>
															</p>
															<Button 
																color='warning' 
																icon='AutoAwesome'
																onClick={() => toggleAssistantMessageEnabled(!!!selectedUser?.assistant_message_enabled)}
																isDisable={isLoadingToggleAssistantMessageEnable}
															>
																{isLoadingToggleAssistantMessageEnable && <Spinner isSmall />}
																Disable AI Auto-Response
															</Button>
														</div>
													</>
													: <div className='flex items-center w-full h-full'>
														<Button 
															color='success' 
															icon={isLoadingToggleAssistantMessageEnable ? undefined : 'AutoAwesome'}
															onClick={() => toggleAssistantMessageEnabled(!!!selectedUser?.assistant_message_enabled)}
															isDisable={isLoadingToggleAssistantMessageEnable}
															className='group'
														>
															{isLoadingToggleAssistantMessageEnable && <Spinner isSmall />}
															<span className='inline-flex !-ml-1 transition-all overflow-hidden whitespace-nowrap w-0 group-hover:w-[170px] group-hover:!ml-0'>Enable AI Auto-Response</span>
														</Button>
														<Input 
															name='message' 
															ariaLabel='Message' 
															placeholder='Write a message...' 
															className='flex-1 bg-transparent shadow-none border-0 outline-0 !m-0 !h-full font-normal'
															value={message}
															onChange={(e:any) => setMessage(e.target.value)}
															onKeyDown={(e:any) => {
																if (e.key == 'Enter') {
																	e.preventDefault();
																	sendMessage();
																}
															}}
														/>
														<div className='absolute top-[50%] -translate-y-1/2 right-9 flex items-center gap-4'>
															<div>
																{isLoadingUpload
																	? <Spinner isSmall className='-mx-1' />
																	: <Icon 
																		color='dark' 
																		icon='AddAPhoto' 
																		size="2x"
																		className='cursor-pointer transition-all hover:scale-[0.9] -mx-1'
																		onClick={() => inputFileRef.current.click()}
																	/>
																}
																<input onChange={uploadConversationFiles} type='file' multiple accept='image/*' className='hidden' ref={inputFileRef} />
															</div>
															<div className='relative'>
																<div ref={emojiPickerRef} className={`absolute bottom-12 right-0 transition-all overflow-hidden ${!isOpenEmojiPicker && `!h-0`}`}>
																	<EmojiPicker 
																		onEmojiClick={(obj) => { handleOnClickEmoji(obj.emoji) }}
																		autoFocusSearch={true}
																		theme={darkModeStatus ? "dark" : "light" as any}
																		emojiStyle={"native" as any}
																		width={"100%"}
																	/>
																</div>
																<Icon 
																	color='dark' 
																	icon='EmojiEmotions' 
																	size="2x"
																	className='cursor-pointer transition-all hover:scale-[0.9] -mx-1'
																	onClick={() => setIsOpenEmojiPicker(true)}
																/>
															</div>
															<Button 
																color='info' 
																icon='Send' 
																onClick={sendMessage}
															/>
														</div>
													</div>
												}
											</CardFooter>
										{/* } */}
									</Card>
									: <Card stretch>
										<div className='w-full h-full flex flex-col gap-4 items-center justify-center p-10'>
											<img src="/images/girl-with-coffee.png" className='w-full max-w-[275px] drop-shadow-xl' />
											<span className='text-2xl text-gray-300 font-semibold'>Select a conversation to start</span>
										</div>
									</Card>
							}
						</div>
					)}
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Conversations;
