import React, { FC, useEffect, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Checks from "../../../components/bootstrap/forms/Checks";
import Api from "../../../services/Api";
import Spinner from "../../../components/bootstrap/Spinner";
import Button from "../../../components/bootstrap/Button";
import InputGroup from "../../../components/bootstrap/forms/InputGroup";
import Label from "../../../components/bootstrap/forms/Label";
import { isValidHttpsUrl } from "../../../helpers/helpers";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import { StatusBadge } from "./TextKnowledge";

interface ICardProps {
    formik: any;
    className?: string;
    actualIndex?: number;
}

const CardSelectPages: FC<ICardProps> = ({
    formik, 
    className,
    actualIndex 
}) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingNewWebsite, setIsLoadingNewWebsite ] = useState(false);
    const [ newWebsite, setNewWebsite ] = useState("");
    const [ manualURL, setManualURL ] = useState("");

    const goToTop = () => {
        var containerDiv:any = document.getElementById("pagescontainer");
        if (containerDiv) {
            setTimeout(() => {
                containerDiv.scrollTop = 0;
            }, 10);
        }
    }

    const addManualURL = () => {
        if (!isValidHttpsUrl(manualURL)) {
            showNotification(`Warning`,`Please enter a valid URL. It must start with "https://".`,`warning`);
            setIsLoading(false);
            return;
        }
        formik.setFieldValue('sources', 
            [
                ...Array.from(
                    new Set([manualURL])).sort((a:any, b:any) => a.localeCompare(b)
                ).filter(
                    (page:any) => (formik?.values?.sources || []).filter((e:any) => e.label == page)?.length == 0
                ).map((e:any) => { 
                    return { label: e, type: 'website-link', selected: true }
                }),
                ...(formik?.values?.sources || [])
            ]
        );
        setManualURL("");
        showNotification(`Success`, `URL successfully added to knowlededge base`, `success`);
        goToTop();
    }

    const allLinksSelected = (origin:string) => {
        let flag = true;
        formik?.values?.sources?.map((e:any) => {
            if (e?.type == 'website-link') {
                var _origin = (new URL(e.label)).origin;
                if (_origin == origin) {
                    if (!e?.selected) {
                        flag = false;
                    }
                }
            }
        });
        return flag;
    }

    // const deleteLink = (label:string) => {
    //     formik.setFieldValue('sources',
    //         formik?.values?.sources?.map((e:any) => {
    //             if (e.label == label) {
    //                 if (e?.id) {

    //                 }
    //                 return false;
    //             }
    //             return true;
    //         })
    //     );
    // }
    
    const deleteLink = (label:string) => {
        let _sources = (formik?.values?.sources || []);
        let source = _sources?.filter((e:any) => e?.label == label)?.[0];
        console.log(_sources, source);
        if (source?.id) {
            _sources = _sources.map((e:any, key:number) => {
                if (e?.label == label) {
                    e.action = 'delete'; 
                }
                return e;
            });
        } else {
            _sources = _sources.filter((e:any, key:number) => e?.label != label);
        }
        formik.setFieldValue('sources', _sources);
    }

    const deleteAll = (origin:string) => {
        formik.setFieldValue('sources',
            formik?.values?.sources?.map((e:any) => {
                if (e?.type == 'website-link') {
                    var _origin = (new URL(e.label)).origin;
                    if (_origin == origin) {
                        e.action = 'delete';
                    }
                    return e;
                }
            })
        );
    }

    const selectUnselectAll = (origin:string) => {
        var value = allLinksSelected(origin) ? false : true;
        formik.setFieldValue('sources',
            formik?.values?.sources?.map((e:any) => {
                if (e?.type == 'website-link') {
                    var _origin = (new URL(e.label)).origin;
                    if (_origin == origin) {
                        e.selected = value;
                        e.action = 'update';
                    }
                }
                return e;
            })
        );
    }

    const searchLinks = () => {
        if (!!newWebsite?.trim() && !isValidHttpsUrl(newWebsite)) {
            showNotification(`Warning`,`Please enter a valid URL. It must start with "https://".`,`warning`);
            setIsLoading(false);
            return;
        }
        setIsLoadingNewWebsite(true);
        !newWebsite && setIsLoading(true);
        Api.get(`/scraper/links?url=${encodeURI(!!newWebsite?.trim() ? newWebsite : formik?.values?.website)}`).then(({ data: pages }) => {
            pages = [`${!!newWebsite?.trim() ? newWebsite : formik?.values?.website}/`, ...pages];
            formik.setFieldValue('sources', 
                [
                    ...Array.from(
                        new Set(pages)).sort((a:any, b:any) => a.localeCompare(b)
                    ).filter(
                        (page:any) => (formik?.values?.sources || []).filter((e:any) => e.label == page)?.length == 0
                    ).map((e:any) => { 
                        return { label: e, type: 'website-link', selected: true }
                    }),
                    ...(formik?.values?.sources || [])
                ]
            );
            if (newWebsite) {
                setNewWebsite("");
                showNotification(`Success`,`Successfully navigated ${newWebsite}.`,`success`);
            }
            goToTop();
        }).finally(() => {
            setIsLoading(false);
            setIsLoadingNewWebsite(false);
        });
    }
    
    useEffect(() => {
        if (actualIndex == 1 && !formik?.values?.sources?.length) {
            searchLinks();
        }
    }, [actualIndex]);
    
    if (!formik) return <></>;
    
    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='TravelExplore' iconColor='info'>
                    <CardTitle>Teach Chatbro</CardTitle>
                    <CardSubTitle>Select which pages you want Chatbro to learn from</CardSubTitle>
                </CardLabel>
                {!!formik?.values?.id && 
                    <CardActions>
                        <InputGroup>
                            <FormGroup
                                id='website'
                                label='Search new website'
                                isFloating>
                                <Input 
                                    onChange={(e:any) => setNewWebsite(e.target.value)}
                                    value={newWebsite}
                                    disabled={isLoading || isLoadingNewWebsite}
                                    onKeyDown={(e) => { 
                                        if (e.key == 'Enter') {
                                            e.preventDefault();
                                            searchLinks();
                                        }
                                    }}
                                />
                            </FormGroup>
                            <Button
                                color='info'
                                isLight
                                icon={isLoading || isLoadingNewWebsite ? undefined : 'TravelExplore'}
                                isDisable={isLoading || isLoadingNewWebsite}
                                onClick={searchLinks}>
                                {isLoading || isLoadingNewWebsite 
                                    ? <><Spinner isSmall isGrow />&nbsp; Loading</>
                                    : `Search`
                                }
                            </Button>
                        </InputGroup>
                    </CardActions>
                }
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    {!formik?.values?.id && 
                        <div className="col-md-12">
                            <InputGroup>
                                <FormGroup
                                    id='website'
                                    label='Navigate new website'
                                    isFloating>
                                    <Input 
                                        onChange={(e:any) => setNewWebsite(e.target.value)}
                                        value={newWebsite || formik?.values?.website}
                                        disabled={isLoading || isLoadingNewWebsite}
                                        onKeyDown={(e) => { 
                                            if (e.key == 'Enter') {
                                                e.preventDefault();
                                                searchLinks();
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <Button
                                    color='info'
                                    isLight
                                    icon={isLoading || isLoadingNewWebsite ? undefined : 'TravelExplore'}
                                    isDisable={isLoading || isLoadingNewWebsite}
                                    onClick={searchLinks}>
                                    {isLoading || isLoadingNewWebsite 
                                        ? <><Spinner isSmall isGrow />&nbsp; Loading</>
                                        : `Search`
                                    }
                                </Button>
                            </InputGroup>
                        </div>
                    }
                    <div className='col-md-12'>
                        {isLoading
                            ? <div className="w-full h-[250px] flex flex-col text-center gap-6 items-center justify-center text-gray-500 font-bold text-base">
                                <Spinner isGrow />
                                <div>
                                    Chatbros is navigating your website.<br/>
                                    This could take a few moments.
                                </div>
                            </div>
                            : <>
                                {!!formik?.values?.sources?.length  
                                    ? <FormGroup
                                        id='website'
                                        // formText={`${formik?.values?.sources?.filter((e:any) => e?.selected)?.length || 0} pages selected.`}
                                        // label='Pages'
                                        className="flex flex-col gap-3"
                                        >
                                            {/* <InputGroup>
                                                <Input 
                                                    placeholder="Or manually add a URL..."
                                                    // className="bg-white"
                                                    value={manualURL}
                                                    onChange={(e:any) => setManualURL(e.target.value)}
                                                    onKeyDown={(e:any) => {
                                                        if (e.key == 'Enter') {
                                                            e.preventDefault();
                                                            addManualURL();
                                                        }
                                                    }}
                                                />
                                                <Button icon="AddCircle" color="info" isLight onClick={addManualURL}>Add</Button>
                                            </InputGroup> */}
                                            {[...new Set((formik?.values?.sources || []).filter((e:any) => e?.type == 'website-link' && e?.action != 'delete').map((e:any) => (new URL(e?.label)).origin)) as any].map((website, key) => (
                                                <div id="pagescontainer" className="form-control flex flex-col gap-4 !h-full max-h-[300px] overflow-y-auto !pt-4 overflow-hidden w-full">
                                                    <div key={key} className="flex flex-col gap-1">
                                                        <div className="flex items-center font-[500] text-[#6c757d] text-lg mb-2">
                                                            {/* <Checks
                                                                className="cursor-pointer"
                                                                color="info"
                                                                onChange={(e:any) => selectUnselectAll(website)}
                                                                checked={allLinksSelected(website)}
                                                            /> */}
                                                            <span className="cursor-pointer" onClick={() => selectUnselectAll(website)}>{website}</span>
                                                            <Button onClick={() => selectUnselectAll(website)} size='sm' isLight className="!py-0.5 ml-3 !text-sm" color="info">{allLinksSelected(website) ? `Unselect` : `Select`} all</Button>
                                                            <Button onClick={() => deleteAll(website)} size='sm' isLight className="!py-0.5 ml-3 !text-sm" color="danger">Delete all</Button>
                                                        </div>
                                                        {(formik?.values?.sources || []).map((item:any, key:any) => {
                                                            if (item?.action == 'delete') return <></>;
                                                            return (<>
                                                                {item?.type == 'website-link' && (new URL(item?.label)).origin == website && 
                                                                    <div className="flex justify-between items-center gap-4 w-full">
                                                                        <Checks
                                                                            id={`sources_${key}`}
                                                                            name='sources'
                                                                            formCheckClassName="w-[85%]"
                                                                            className="cursor-pointer flex-1"
                                                                            labelClassName="w-full overflow-hidden"
                                                                            color="info"
                                                                            onChange={(e:any) => {
                                                                                formik.setFieldValue('sources', 
                                                                                    formik.values.sources.map(
                                                                                        (wp:any, i:number) => { 
                                                                                            if (i == key) { 
                                                                                                wp = {...wp, selected: e.target.checked, action: 'update'}; 
                                                                                            } 
                                                                                            return wp; 
                                                                                        }
                                                                                    )
                                                                                );
                                                                                // if (e.target.checked) {
                                                                                //     formik.setFieldValue('sources', [...(formik.values.sources || []), item]);
                                                                                // } else {
                                                                                //     formik.setFieldValue('sources', formik.values.sources?.filter((e:any) => e != item));
                                                                                // }
                                                                            }}
                                                                            checked={item?.selected}
                                                                            label={
                                                                                <div title={item?.label} className="flex items-center justify-start gap-4">
                                                                                    <span className="flex-1 cursor-pointer block overflow-hidden text-overflow-ellipsis text-ellipsis whitespace-nowrap">
                                                                                        {item?.label}
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        />
                                                                        <div className="flex items-center gap-1.5 shrink-0">
                                                                            {!!formik?.values?.id && !!item?.selected && <StatusBadge status_message={item?.status_message} status={item?.status} />}
                                                                            <Icon 
                                                                                color="danger"
                                                                                icon="Delete"
                                                                                onClick={(e) => { 
                                                                                    e.stopPropagation();
                                                                                    e.preventDefault();
                                                                                    deleteLink(item?.label);
                                                                                }}
                                                                                className="cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>)
                                                        })}
                                                    </div>
                                                </div>
                                            ))}
                                    </FormGroup>
                                    : <p className="text-gray-400 text-base font-semibold px-2 mt-0">No pages found, please try searching another website.</p>
                                }
                            </>
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardSelectPages;