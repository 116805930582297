import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Wizard, { WizardItem } from '../../../components/Wizard';
import dayjs from "dayjs";
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import { isValidHttpsUrl } from "../../../helpers/helpers";
import { SessionContext } from "../../../contexts/SessionContext";
import CardCompanyDetails from "./CardCompanyDetails";
import CardLanguageConfiguration from "./CardLanguageConfiguration";
import CardGoal from "./CardGoal";
import CardLeads from "./CardLeads";
import CardSelectPages from "./CardSelectPages";
import CardAvatar from "./CardAvatar";
import CardThemeColors from "./CardThemeColors";
import Api from "../../../services/Api";
import axios from "axios";
import Spinner from "../../../components/bootstrap/Spinner";
import { PricingModalContext } from "../../../contexts/PricingModalContext";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import useMinimizeAside from "../../../hooks/useMinimizeAside";
import CardSalesperson from "./CardSalesperson";

export const leadFieldOptions = [
    // General
    { title: "Name", id: "Name", category: "General" },
    { title: "Phone number", id: "Phone number", category: "General" },
    { title: "Email", id: "Email", category: "General" },
    { title: "Country", id: "Country", category: "General" },
    { title: "State/province", id: "State/province", category: "General" },
    { title: "City", id: "City", category: "General" },
    { title: "Postal code", id: "Postal code", category: "General" },
    { title: "Gender", id: "Gender", category: "General" },
    { title: "Age", id: "Age", category: "General" },
    { title: "Birth Date", id: "Birth Date", category: "General" },
    { title: "Preferred contact time", id: "Preferred contact time", category: "General" },
    { title: "Preferred contact method", id: "Preferred contact method", category: "General" },
    // // Company
    // { title: "Company name", id: "Company name", category: "Company" },
    // { title: "Company activity", id: "Company activity", category: "Company" },
    // { title: "Company size", id: "Company size", category: "Company" },
    // { title: "Job title", id: "Job title", category: "Company" },
    // // Automotive
    // { title: "Car make", id: "Car make", category: "Automotive" },
    // { title: "Car model", id: "Car model", category: "Automotive" },
    // { title: "Car year", id: "Car year", category: "Automotive" },
    // { title: "Customer budget for car", id: "Customer budget for car", category: "Automotive" },
    // { title: "Has own car", id: "Has own car", category: "Automotive" },
    // { title: "Own car details", id: "Own car details", category: "Automotive" },
    // { title: "Own car make", id: "Own car make", category: "Automotive" },
    // { title: "Own car model", id: "Own car model", category: "Automotive" },
    // { title: "Own car year", id: "Own car year", category: "Automotive" },
    // // Real Estate
    // { title: "Property type", id: "Property type", category: "Real Estate" },
    // { title: "Property value", id: "Property value", category: "Real Estate" },
    // { title: "Property country", id: "Property country", category: "Real Estate" },
    // { title: "Property state/province", id: "Property state/province", category: "Real Estate" },
    // { title: "Property city", id: "Property city", category: "Real Estate" },
    // { title: "Property details", id: "Property details", category: "Real Estate" },
    // { title: "Property rooms", id: "Property rooms", category: "Real Estate" },
    // { title: "Property bathrooms", id: "Property bathrooms", category: "Real Estate" },
    // { title: "Customer budget for property", id: "Customer budget for property", category: "Real Estate" },
    // // Finance
    // { title: "Credit score", id: "Credit score", category: "Finance" },
    // { title: "Income source", id: "Income source", category: "Finance" },
    // { title: "Yearly income", id: "Yearly income", category: "Finance" },
    // { title: "Monthly income", id: "Monthly income", category: "Finance" },
    // { title: "Investment amount", id: "Investment amount", category: "Finance" },
];

export const goalOptions = [
    { 
        value: "customer-support", 
        text: "👩‍💻 Assistant",
        description: "Your Chatbro as an assistant excels in providing top-notch customer support. It swiftly handles inquiries, troubleshoots concerns, and ensures your customers have a seamless experience." 
    },
    { 
        value: "salesperson", 
        text: "👨‍💼 Salesperson",
        description: "Transform your Chatbro into a sales powerhouse. Enable it to engage visitors, showcase products/services, and guide potential customers through the sales process, ultimately driving conversions." 
    },
    { 
        value: "lead-generation", 
        text: "🎯 Lead Generation",
        description: "Utilize your Chatbro to capture leads effectively. Offer a tailored form to your website visitors, gathering vital information for future follow-ups and opportunities to nurture them into potential customers." 
    },
    { 
        value: "copywriter", 
        text: "✍️ Copywriter (soon)", disabled: true 
    },
    { 
        value: "knowledge-base", 
        text: "📚 Knowledge Base (soon)", disabled: true 
    },
    { 
        value: "survey-gatherer", 
        text: "📋 Survey Gatherer (soon)", disabled: true 
    },
    { 
        value: "appointment-schedule", 
        text: "🗓 Appointment Schedule (soon)", disabled: true 
    },
]

export const toneOptions = [
    { value: "Friendly", text: `😊  Friendly` },
    { value: "Formal", text: `🤵  Formal` },
    { value: "Humorous", text: `🤣  Humorous` },
    { value: "Informal", text: `😜  Informal` },
    { value: "Ironic", text: `🙃  Ironic` },
    { value: "Positive", text: `🤩  Positive` },
    { value: "Exciting", text: `🥳  Exciting` },
    { value: "Angry", text: `😡  Angry` },
    { value: "Affectionate", text: `🥰  Affectionate` },
    { value: "Melancholic", text: `🥺  Melancholic` },
    { value: "Dramatic", text: `😱  Dramatic` },
    { value: "Scientific", text: `🔬  Scientific` },
    { value: "Creative", text: `🎨  Creative` },
    { value: "Descriptive", text: `📖  Descriptive` },
    { value: "Educational", text: `🎓  Educational` },
]

export const languageOptions = [
    { value: "en", text: "🇺🇸 English" },
    { value: "es", text: "🇪🇸 Spanish" },
    { value: "fr", text: "🇫🇷 French" },
    { value: "de", text: "🇩🇪 German" },
    { value: "it", text: "🇮🇹 Italian" },
    { value: "pt", text: "🇵🇹 Portuguese" },
    { value: "nl", text: "🇳🇱 Dutch" },
    { value: "ru", text: "🇷🇺 Russian" },
    { value: "ja", text: "🇯🇵 Japanese" },
    { value: "ko", text: "🇰🇷 Korean" },
    { value: "zh", text: "🇨🇳 Chinese" },
    { value: "ar", text: "🇸🇦 Arabic" },
    { value: "hi", text: "🇮🇳 Hindi" },
    { value: "fil", text: "🇵🇭 Filipino" },
    { value: "id", text: "🇮🇩 Indonesian" },
    { value: "ms", text: "🇲🇾 Malay" },
    { value: "vi", text: "🇻🇳 Vietnamese" },
    { value: "th", text: "🇹🇭 Thai" },
    { value: "tr", text: "🇹🇷 Turkish" },
    { value: "sv", text: "🇸🇪 Swedish" },
    { value: "no", text: "🇳🇴 Norwegian" },
    { value: "da", text: "🇩🇰 Danish" },
    { value: "pl", text: "🇵🇱 Polish" },
    { value: "cs", text: "🇨🇿 Czech" },
    { value: "sk", text: "🇸🇰 Slovak" },
    { value: "hu", text: "🇭🇺 Hungarian" },
    { value: "ro", text: "🇷🇴 Romanian" },
    { value: "bg", text: "🇧🇬 Bulgarian" },
    { value: "sr", text: "🇷🇸 Serbian" },
    { value: "hr", text: "🇭🇷 Croatian" },
    { value: "el", text: "🇬🇷 Greek" },
    { value: "he", text: "🇮🇱 Hebrew" }
];

interface INewChatbotModalProps {
	isOpenNewModal: boolean;
	setIsOpenNewModal: any;
    getChatbots?: any;
}

export const validateChatbot = (values:any, showMessage:boolean = false) => {
    const errors: { name?: string; company_name?: string; website?: string; } = {};
    if (!values.name) {
        errors.name = 'You need to enter a name';
        showMessage && showNotification(`Validation Errors`, `You need to enter a name`, `warning`);
    } 
    if (!values.company_name) {
        errors.company_name = 'You need to enter a company name';
        showMessage && showNotification(`Validation Errors`, `You need to enter a company name`, `warning`);
    } 
    if (!isValidHttpsUrl(values.website)) {
        errors.website = 'Please enter a valid URL. It must start with "https://".';
        showMessage && showNotification(`Validation Errors`, `Please enter a valid URL. It must start with "https://".`, `warning`);
    }
    return errors;
}

export const validateChatbotBool = (values:any) => {
    const errors = validateChatbot(values, true);
    for (const prop in errors) {
        if (Object.hasOwn(errors, prop)) {
            return false;
        }
    }
    return true;
}

export const chatbotFormInitialValues = {
    id: undefined,
    active: 1,
    type: 'customer-support',
    name: `Chatbro ${dayjs().format('YYYY-MM-DD')}`,
    language_iso_code: 'en',
    communication_tone: 'Friendly',
    company_name: '',
    company_description: '',
    website: '',
    image: '',
    new_image: '',
    social_network_image: '',
    primary_color: '#2E56A7',
    secondary_color: '#BB6DE5',
    lead_fields: leadFieldOptions.slice(0, 3).map(e => e.id),
    sources: [],
    assistant_name: '',
    welcome_message: '',
    show_welcome_message: 1,
    show_form: 1,
    external_id: undefined,
    predefined_buttons: [],
    web_social_network: { id: 0, image: { url: "" } },
    description: undefined,
};

const NewChatbotModal: FC<INewChatbotModalProps> = ({
    isOpenNewModal,
    setIsOpenNewModal
}) => {

    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const { user, updateUserData } = useContext<any>(SessionContext);
    const { isOpenPricingModal, setIsOpenPricingModal } = useContext(PricingModalContext);
    const [ actualIndex, setActualIndex ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);

	const formik = useFormik({
		initialValues: localStorage.getItem(`newChatbotForm`) ? JSON.parse(String(localStorage.getItem(`newChatbotForm`))) : chatbotFormInitialValues,
		validate: (values) => validateChatbot(values),
		onSubmit: async (values) => {
            if (!validateChatbotBool(values)) return false;
            setIsLoading(true);
            try {
                const chatbot = await Api.post(`/chatbots`, {}, values).then(({ data: chatbot }) => chatbot);
                const socialNetwork = await Api.post(`/socialNetworks`, {}, { 
                    chatbot_id: chatbot?.id, 
                    type: "web", 
                    preferences: {
                        assistant_name: values?.assistant_name, 
                        primary_color: values?.primary_color, 
                        secondary_color: values?.secondary_color,
                        show_welcome_message: 1 
                    }
                }).then(({ data: socialNetwork }) => socialNetwork);
                if (values?.social_network_image) {
                    const blob = await fetch(values?.social_network_image).then(res => res.blob());
                    const imageType = values?.social_network_image?.substring("data:image/".length, values?.social_network_image?.indexOf(";base64"));
                    const name = `chatimage_${chatbot?.id}_${socialNetwork?.id}.${imageType}`;
                    const file = new File([blob], name, { type: `image/${imageType}` });
                    const aws_uri = await Api.post(`/socialNetworks/${socialNetwork?.id}/image`, {}, { filename: name, type: "image", visibility: "public" }).then((response) => { return response.data.presigned_url; });
                    await axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {});
                }
                localStorage.removeItem(`newChatbotForm`);
                localStorage.removeItem(`newChatbotFormStep`);
                await updateUserData();
                navigate(`/chatbots/${chatbot.id}#PREVIEW`);
                showNotification(`Chatbro Created!`, `Your chatbro was successfully created. Now it's time to add it to your website!`, `success`)
                setIsOpenNewModal(false);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
		},
	});

    useEffect(() => {
        localStorage.setItem(`newChatbotForm`, JSON.stringify(formik.values));
    }, [formik.values]);
    
    useEffect(() => {
        setTimeout(() => {
            localStorage.setItem(`newChatbotFormStep`, String(actualIndex));
        }, 100)
    }, [actualIndex]);

    useEffect(() => {
        if ((searchParams.get("subscriptionStatus") == "active" || searchParams.get("subscriptionStatus") == "success") && localStorage.getItem(`newChatbotForm`)) {
            setIsLoading(true);
            setTimeout(() => {
                formik.submitForm();
            }, 500);
        }
    }, [searchParams.get("subscriptionStatus")]);
    
    const validateStep = (step:any) => {
        var errors:any = [];
        if (step == 0) {
            if (!formik.values.name) {
                errors = [...errors, "You need to enter a name"];
            }
            if (!formik.values.company_name) {
                errors = [...errors, "You need to enter the company name"];
            }
            if (!isValidHttpsUrl(formik.values.website)) {
                errors = [...errors, 'Please enter a valid URL. It must start with "https://".'];
            }
        }
        if (errors.length) showNotification("Validation errors", errors.join("\n"), "warning");
        return !errors.length;
    }

    return (
        <Modal
            setIsOpen={setIsOpenNewModal}
            isOpen={isOpenNewModal}
            fullScreen
            titleId='transfer-modal'
            preventEsc={isOpenPricingModal}
            isAnimation={true}
            contentClassName={"!bg-transparent"}
        >
            <ModalHeader isLight className="hidden lg:flex !bg-transparent w-full text-white !fixed !z-[1000]" setIsOpen={() => navigate(`/chatbots`)}>
                <ModalTitle id='transfer-modal'>&nbsp;</ModalTitle>
            </ModalHeader>
            <ModalBody className='p-0 lg:p-inherit h-100 d-flex align-items-center justify-center !bg-transparent w-full' style={{ backgroundImage: 'url(/images/footer-bg-shapes.svg', backgroundSize: '200%', backgroundPosition: '100px 50%', backgroundRepeat: 'no-repeat'}}>
                <div className='row max-w-full lg:w-full h-auto max-h-full items-center !m-0'>
                    <div className='col-lg-5 mx-auto !p-0 lg:p-inherit shadow-3d-container h-full lg:h-auto'>
                        {isLoading
                            ? <Card className="w-full">
                                <CardBody className="h-[400px] flex flex-col items-center justify-center">
                                    <Spinner isGrow />
                                </CardBody>
                            </Card>
                            : <Wizard
                                isHeader
                                color='primary'
                                onSubmit={formik.handleSubmit}
                                setIndex={setActualIndex}
                                icon="SmartToy"
                                className='shadow-3d-info'
                                initialStep={localStorage.getItem(`newChatbotFormStep`) as unknown as number || 0}
                                validateStep={validateStep}
                            >
                                <WizardItem id='step1' title='Chatbro Details'>
                                    <CardCompanyDetails formik={formik} />
                                    <CardLanguageConfiguration formik={formik} className='mb-0' />
                                </WizardItem>

                                <WizardItem id='step2' title='Chatbro Goal'>
                                    <CardGoal formik={formik} />
                                    <CardLeads formik={formik} className={`transition-all -mt-4 h-0 scale-0 ${formik.values.type == 'lead-generation' && `m-0 h-auto scale-[1]`}`} />
                                    <CardSalesperson formik={formik} className={`transition-all -mt-4 h-0 scale-0 ${formik.values.type == 'salesperson' && `m-0 h-auto scale-[1]`}`} />
                                </WizardItem>
                                
                                <WizardItem id='step3' title='Teach Chatbro'>
                                    <CardSelectPages actualIndex={actualIndex} formik={formik} />
                                </WizardItem>

                                {/* <WizardItem id='step4' title='Customization'>
                                    <CardAvatar formik={formik} />
                                    <CardThemeColors formik={formik} />
                                </WizardItem> */}
                            </Wizard>
                        }
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default NewChatbotModal;