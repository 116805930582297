import React, { FC } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import { demoPagesMenu } from '../../../menu';
import Chart, { IChartOptions } from '../../../components/extras/Chart';
import useDarkMode from '../../../hooks/useDarkMode';

interface IConversationsCardProps {
    conversationsCount?: any;
    conversations?: any;
}

const ConversationsCard: FC<IConversationsCardProps> = ({
	conversationsCount,
	conversations
}) => {

	console.log(conversations);

	const { darkModeStatus } = useDarkMode();
	const approvedAppointments: IChartOptions = {
		series: [
			{
				name: 'Conversations',
				data: conversations?.series?.slice(-7),
			},
		],
		options: {
			colors: [process.env.REACT_APP_SUCCESS_COLOR],
			chart: {
				type: 'bar',
				height: 150,
				dropShadow: {
					enabled: false,
					color: process.env.REACT_APP_SUCCESS_COLOR,
					top: 0,
					left: 0,
					blur: 10,
					opacity: 0.5,
				},
				toolbar: {
					show: false,
				},
				redrawOnParentResize: true,
				redrawOnWindowResize: true,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					// columnWidth: '35%',
					borderRadius: 5,
				},
			},
			stroke: {
				show: true,
				width: 12,
				colors: ['transparent'],
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				categories: [
					dayjs().subtract(6, 'days').format(),
					dayjs().subtract(5, 'days').format(),
					dayjs().subtract(4, 'days').format(),
					dayjs().subtract(3, 'days').format(),
					dayjs().subtract(2, 'days').format(),
					dayjs().subtract(1, 'days').format(),
					dayjs().format(),
				],
				labels: {
					rotate: -15,
					rotateAlways: true,
				},
			},
			yaxis: {
				labels: {
					show: false,
				},
			},
			tooltip: {
				theme: 'dark',
				y: {
					formatter(val: number): string {
						return `${val} Conversations`;
					},
				},
			},
			grid: {
				show: false,
			},
		},
	};
	return (
		<Card
			className={classNames('shadow-3d-success', {
				'text-dark': darkModeStatus,
				'bg-lo50-success': darkModeStatus,
				'bg-l25-success': !darkModeStatus,
			})}
			isCompact>
			<CardHeader className='bg-transparent'>
				<CardLabel>
					<CardTitle>Conversations</CardTitle>
					<CardSubTitle>This month</CardSubTitle>
				</CardLabel>
				<CardActions>
					<Button
						color='success'
						tag='a'
						to={`../${demoPagesMenu.conversations.path}`}>
						View Conversations
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody>
				<div className='-mb-7'>
					<span className='display-3 me-3'>{conversationsCount}</span>
				</div>
				<Chart
					series={approvedAppointments.series}
					options={approvedAppointments.options}
					type={approvedAppointments.options.chart?.type}
					height={approvedAppointments.options.chart?.height}
				/>
			</CardBody>
		</Card>
	);
};

export default ConversationsCard;
