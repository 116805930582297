import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Calendar, dayjsLocalizer, View as TView, Views } from 'react-big-calendar';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Avatar from '../../../components/Avatar';
import User1Webp from '../../../assets/img/wanna/wanna2.webp';
import User1Img from '../../../assets/img/wanna/wanna2.png';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Icon from '../../../components/icon/Icon';
import capitalize, { hasActiveSubscription, priceFormat } from '../../../helpers/helpers';
import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import CardCompanyDetails from './CardCompanyDetails';
import CardLanguageConfiguration from './CardLanguageConfiguration';
import CardGoal from './CardGoal';
import CardLeads from './CardLeads';
import CardSelectPages from './CardSelectPages';
import CardAvatar from './CardAvatar';
import CardThemeColors from './CardThemeColors';
import CardWebIntegration from './CardWebIntegration';
import Api from '../../../services/Api';
import showNotification from '../../../components/extras/showNotification';
import Spinner from '../../../components/bootstrap/Spinner';
import CardWelcomeMessage from './CardWelcomeMessage';
import CardQuickButtons from './CardQuickButtons';
import { chatbotFormInitialValues, validateChatbot, validateChatbotBool } from './NewChatbotModal';
import CardActivateChatbot from './CardActivateChatbot';
import CardInitialForm from './CardInitialForm';
import Badge from '../../../components/bootstrap/Badge';
import axios from 'axios';
import { SessionContext } from '../../../contexts/SessionContext';
import { PricingModalContext } from '../../../contexts/PricingModalContext';
import useMinimizeAside from '../../../hooks/useMinimizeAside';
import CardCompanyLogo from './CardCompanyLogo';
import CardTextKnowledge from './CardTextKnowledge';
import LearningAlert from './LearningAlert';
import PreviewChatbroModal from './PreviewChatbroModal';
import CardSalesperson from './CardSalesperson';
import DeleteChatbroModal from './DeleteChatbroModal';
import PreviewChatbro from './PreviewChatbro';

const localizer = dayjsLocalizer(dayjs);
const now = new Date();

function getKeyByValue(object:any, value:any) {
    return Object.keys(object).find(key => object[key] === value);
}

const EditChatbotModal = () => {

    const tabData: any = [
        {
            id: "PREVIEW",
            title: 'Preview Chatbro',
            icon: 'Visibility',
            subtitle: 'Save your changes to update the preview',
            color: 'primary'
        },
        {
            divider: true
        },
        {
            id: "CHATBRO_DETAILS",
            title: 'Configuration',
            icon: 'Settings',
            subtitle: 'Configure language, communication tone, and more',
            color: 'info'
        },
        {
            id: "GOAL_SETTINGS",
            title: 'Goal Settings',
            icon: 'SupportAgent',
            subtitle: 'Configure your Chatbro goal',
            color: 'info'
        },
        {
            id: "TEACH_CHATBRO",
            title: 'Knowledge',
            icon: 'School',
            subtitle: 'Teach your Chatbro about your company or brand',
            color: 'info'
        },
        {
            id: "QUICK_BUTTONS",
            title: 'Quick Buttons',
            icon: 'SmartButton',
            subtitle: 'Configure Quick Buttons to make your Chatbro even easier to use',
            color: 'info'
        },
        {
            divider: true
        },
        {
            id: "CUSTOMIZATION",
            title: 'Customization',
            icon: 'ColorLens',
            subtitle: 'Match your Chatbro\'s look and feel with your company\'s.',
            color: 'success'
        },
        {
            divider: true
        },
        {
            id: "WEB_INTEGRATION",
            title: 'Web Integration',
            icon: 'Public',
            subtitle: 'Integrate your Chatbro in your website. It\'s as easy as it can be.',
            color: 'dark'
        },
        // {
        //     id: "FACEBOOK_INTEGRATION",
        //     title: 'Facebook Integration',
        //     icon: <img src="/images/facebook.svg" className='h-full' />,
        //     subtitle: 'Integrate your Chatbro in your website. It\'s as easy as it can be.',
        //     color: 'dark',
        //     disabled: true,
        //     soon: true
        // },
        // {
        //     id: "INSTAGRAM_INTEGRATION",
        //     title: 'Instagram Integration',
        //     icon: <img src="/images/instagram.svg" className='h-full' />,
        //     subtitle: 'Integrate your Chatbro in your website. It\'s as easy as it can be.',
        //     color: 'dark',
        //     disabled: true,
        //     soon: true
        // }
    ]

	// useMinimizeAside();

    const params = useParams();
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const [ activeTab, setActiveTab ] = useState<any>(tabData[0].id);
    const [ isLoading, setIsLoading ] = useState(false);
    const { hash } = useLocation();
    const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);
    const [ isOpenPreviewModal, setIsOpenPreviewModal ] = useState(false);
    const [ isOpenModalDeleteChatbro, setIsOpenModalDeleteChatbro ] = useState(false);

    const activeTabObj = tabData?.filter((e:any) => e.id == activeTab)?.[0];

	const formik = useFormik({
		initialValues: chatbotFormInitialValues,
		validate: (values) => validateChatbot(values),
		onSubmit: async (values) => {
            if (!validateChatbotBool(values)) return false;
            if (!(values as any)?.dontLoad) {
                setIsLoading(true);
            }
            try {
                let _sources:any = values?.sources?.filter((e:any) => !!e?.type)?.map((e:any) => { 
                    return {
                        ...e, 
                        label: e?.label?.trim() || capitalize(e?.type)
                    }
                });
                values = { ...values, sources: _sources };
                const chatbot = await Api.put(`/chatbots/${values?.id}`, {}, Object.fromEntries(Object.entries(values).map(([k, v]) => [k, v === null ? undefined : v]))).then(({ data: chatbot }) => chatbot);
                const socialNetwork = await Api.put(`/socialNetworks/${values.web_social_network?.id}`, {}, { 
                    type: "web", 
                    preferences: {
                        primary_color: values?.primary_color,
                        secondary_color: values?.secondary_color,
                        assistant_name: values?.assistant_name,
                        show_welcome_message: values?.show_welcome_message,
                        welcome_message: values?.welcome_message,
                        show_form: values?.show_form,
                    }
                }).then(({ data: socialNetwork }) => socialNetwork);
                if (values?.social_network_image) {
                    const blob = await fetch(values?.social_network_image).then(res => res.blob());
                    const imageType = values?.social_network_image?.substring("data:image/".length, values?.social_network_image?.indexOf(";base64"));
                    const name = `chatimage_${chatbot?.id}_${socialNetwork?.id}.${imageType}`;
                    const file = new File([blob], name, { type: `image/${imageType}` });
                    const aws_uri = await Api.post(`/socialNetworks/${socialNetwork?.id}/image`, {}, { filename: name, type: "image", visibility: "public" }).then((response) => { return response.data.presigned_url; });
                    await axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {});
                }
                if (values?.new_image) {
                    const blob = await fetch(values?.new_image).then(res => res.blob());
                    const imageType = values?.new_image?.substring("data:image/".length, values?.new_image?.indexOf(";base64"));
                    const name = `companyimage_${chatbot?.id}_${socialNetwork?.id}.${imageType}`;
                    const file = new File([blob], name, { type: `image/${imageType}` });
                    const aws_uri = await Api.post(`/chatbots/${chatbot?.id}/image`, {}, { filename: name, type: "image", visibility: "public" }).then((response) => { return response.data.presigned_url; });
                    await axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {});
                }
                localStorage.removeItem(`newChatbotForm`);
                localStorage.removeItem(`newChatbotFormStep`);
                if (!(values as any)?.dontLoad) {
                    showNotification(`Chatbro Updated!`, `Your chatbro was successfully updated.`, `success`);
                    getChatbot();
                }
            } catch (err:any) {
                showNotification(`Error`, `An error ocurred while updating: ${err?.message}`, `danger`);
                setIsLoading(false);
            }
		},
	});

    const getChatbot = (load = true) => {
        const id = params?.id;
        load && setIsLoading(true);
        Api.get(`/chatbots/${id}?include=image,socialNetworks,socialNetworks.image,sources`).then(({ data: chatbot }) => {
            formik.setValues({ 
                ...chatbot, 
                active: !hasActiveSubscription(user) ? false : chatbot.active,
                web_social_network: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0],
                primary_color: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.primary_color,
                secondary_color: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.secondary_color,
                assistant_name: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.assistant_name,
                show_welcome_message: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.show_welcome_message,
                welcome_message: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.welcome_message,
                show_form: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.preferences?.show_form,
                external_id: chatbot?.socialNetworks?.filter((e:any) => e.type == 'web')?.[0]?.external_id,
            });
        }).catch((err) => {
            showNotification("Error", err.message, 'danger');
            navigate(`/chatbots`);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (params?.id) {
            getChatbot();
        }
    }, [params?.id]);

    useEffect(() => {
        if (hash) {
            setActiveTab(hash.substring(1));
        }
    }, [hash]);

	return (
		<PageWrapper title={demoPagesMenu.chatbots.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`/chatbots`)}>
						Back to List
					</Button>
					<SubheaderSeparator />
					{(formik?.values?.social_network_image || formik?.values?.web_social_network?.image?.url) && <Avatar className='shrink-0 object-cover !w-[42px] !h-[42px]' src={formik?.values?.social_network_image || formik?.values?.web_social_network?.image?.url} size={32} />}
					<span>
						<strong>{formik.values.company_name}</strong>
					</span>
					<span className='text-muted'>Edit Chatbot</span>
                    <Badge color={formik.values.active ? 'success' : 'danger'}>
                        {formik.values.active ? 'Active' : 'Inactive'}
                    </Badge>
				</SubHeaderLeft>
				<SubHeaderRight>
                    {!hasActiveSubscription(user) && <>
                            <Button
                                color={'success'}
                                icon={'PowerSettingsNew'}
                                onClick={() => setIsOpenPricingModal(true)}
                            >
                                Activate Chatbro
                            </Button>
                    </>}
                    <Button color='info' icon='Save' onClick={formik.handleSubmit} isDisable={!formik.isValid && !!formik.submitCount}>
                        Save
                    </Button>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
                {isLoading 
                    ? <div className='h-100 w-full flex items-center justify-center'>
                        <Spinner isGrow/>
                    </div>
                    : <div className='row h-100'>
                        <div className='col-xxl-3 col-xl-4 col-lg-4'>
                            <Card className='position-sticky sticky-top-size max-h-[82%]'>
                                <CardHeader>
                                    <CardLabel icon='SmartToy' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Edit Chatbot
                                        </CardTitle>
                                        <CardSubTitle tag='div' className='h6'>
                                            Settings menu
                                        </CardSubTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody isScrollable className='flex-1'>
                                    <div className='row g-3'>
                                        {tabData.map((e:any, key:number) => (
                                            <>
                                                {e?.divider
                                                    ? <div className='col-12 border-bottom' />
                                                    : <div className='col-12'>
                                                        <Button
                                                            icon={typeof e.icon === 'string' ? e.icon : undefined}
                                                            color={e.color}
                                                            className={`w-100 p-3 flex items-center justify-center ${e.disabled && `!pointer-events-none !cursor-default !cursor-normal opacity-[0.3]`}`}
                                                            isLight={e.id !== activeTab}
                                                            onClick={() => navigate(`../${demoPagesMenu.chatbots.path}/${params?.id}#${e.id}`)}>
                                                            {typeof e.icon !== 'string' && <div className='h-5 mr-2'>{e.icon}</div>}
                                                            {e.title}
                                                        </Button>
                                                    </div>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <CardFooterLeft className='w-100'>
                                        <Button
                                            icon='Delete'
                                            color='danger'
                                            isLight
                                            className='w-100 p-3'
                                            onClick={() => setIsOpenModalDeleteChatbro(true)}
                                        >
                                            Delete Chatbot
                                        </Button>
                                    </CardFooterLeft>
                                </CardFooter>
                            </Card>
                        </div>
                        <div className='col-xxl-9 col-xl-8 col-lg-8'>
                            <Card className='z-[0]' stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
                                <CardHeader>
                                    <CardLabel icon={activeTabObj?.icon} iconColor={activeTabObj?.color}>
                                        <CardTitle tag='div' className='h5'>
                                            {activeTabObj?.title}
                                        </CardTitle>
                                        <CardSubTitle>
                                            {activeTabObj?.subtitle}
                                        </CardSubTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody isScrollable className='flex-1'>
                                    {activeTab === "CHATBRO_DETAILS" && (<>
                                        <CardActivateChatbot formik={formik} />
                                        <CardCompanyDetails isEdit={true} formik={formik} />
                                        <CardLanguageConfiguration formik={formik} />
                                        <CardWelcomeMessage formik={formik} />
                                        {/* <CardInitialForm formik={formik} /> */}
                                    </>)}
                                    {activeTab === "GOAL_SETTINGS" && (<>
                                        <CardGoal formik={formik} />
                                        <CardLeads formik={formik} className={`transition-all -mt-4 h-0 scale-0 ${formik.values.type == 'lead-generation' && `m-0 h-auto scale-[1]`}`} />
                                        <CardSalesperson formik={formik} className={`transition-all -mt-4 h-0 scale-0 ${formik.values.type == 'salesperson' && `m-0 h-auto scale-[1]`}`} />
                                    </>)}
                                    {activeTab === "TEACH_CHATBRO" && (<>
                                        <LearningAlert formik={formik} />
                                        <CardSelectPages formik={formik} />
                                        <CardTextKnowledge formik={formik} />
                                    </>)}
                                    {activeTab === "QUICK_BUTTONS" && (<>
                                        <CardQuickButtons formik={formik} />
                                    </>)}
                                    {activeTab === "CUSTOMIZATION" && (<>
                                        <CardAvatar formik={formik} />
                                        <CardCompanyLogo formik={formik} />
                                        <CardThemeColors formik={formik} />
                                    </>)}
                                    {activeTab === "WEB_INTEGRATION" && (<>
                                        <CardWebIntegration formik={formik} />
                                    </>)}
                                    {activeTab === "PREVIEW" && (<>
                                        <PreviewChatbro formik={formik} />
                                    </>)}
                                </CardBody>
                                <CardFooter>
                                    <CardFooterLeft>
                                        {/* <Button
                                            color='info'
                                            isLink
                                            type='reset'
                                            onClick={formik.resetForm}>
                                            Reset
                                        </Button> */}
                                    </CardFooterLeft>
                                    <CardFooterRight>
                                        <Button
                                            type='submit'
                                            icon='Save'
                                            color='info'
                                            isDisable={!formik.isValid && !!formik.submitCount}>
                                            Save
                                        </Button>
                                    </CardFooterRight>
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                }
                <PreviewChatbroModal 
                    setIsOpenPreviewModal={setIsOpenPreviewModal}
                    isOpenPreviewModal={isOpenPreviewModal}
                    formik={formik}
                />
                <DeleteChatbroModal 
                    setIsOpenModalDeleteChatbro={setIsOpenModalDeleteChatbro} 
                    isOpenModalDeleteChatbro={isOpenModalDeleteChatbro} 
                    formik={formik}
                />
			</Page>
		</PageWrapper>
	);
};

export default EditChatbotModal;
