import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import Badge from '../../components/bootstrap/Badge';
import Input from '../../components/bootstrap/forms/Input';
import PlaceholderImage from '../../components/extras/PlaceholderImage';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import { demoPagesMenu } from '../../menu';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import GridChatbotItem from './components/GridChatbotItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import NewChatbotModal from './components/NewChatbotModal';
import { SessionContext } from '../../contexts/SessionContext';
import EditChatbotModal from './components/EditChatbotModal';
import Api from '../../services/Api';
import showNotification from '../../components/extras/showNotification';
import Spinner from '../../components/bootstrap/Spinner';


const Chatbots = () => {

    const params = useParams();
    const navigate = useNavigate();
	const location = useLocation();
	const { user } = useContext<any>(SessionContext);
    const [ isOpenNewModal, setIsOpenNewModal ] = useState(false);
	const [ data, setData ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(false);
  
	const getChatbots = () => {
		setIsLoading(true);
		Api.get(`/chatbots?include=socialNetworks,socialNetworks.image,user,user.image&sort=-id`).then(({ data: chatbots }) => {
			setData(chatbots || []);
		}).catch((err) => {
			// showNotification('Error', err.message, 'danger');
		}).finally(() => {
			setIsLoading(false);
		});
	}

    useEffect(() => {
        if (params?.id === 'new') {
            setIsOpenNewModal(true);
        } else {
			setIsOpenNewModal(false);
        }
    }, [params.id, location.pathname]);

	// useEffect(() => {
	// 	if (user?.chatbotsCount == 0) {
	// 		setIsOpenNewModal(true);
	// 	}
	// }, [user?.chatbotsCount, location.pathname]);

	useEffect(() => {
		getChatbots();
	}, []);

	useEffect(() => {
		// if (window.location.href.includes("update")) {
		getChatbots();
		// }
	}, [window.location.href]);

	if (params?.id !== undefined && params?.id != 'new') {
		return <EditChatbotModal />
	}

	return (
		<PageWrapper title={demoPagesMenu.chatbots.text}>
			<SubHeader className='!flex !flex-row !justify-between'>
				<SubHeaderLeft className='!flex !flex-row !w-auto !items-center'>
					<Breadcrumb
						list={[
							{ title: demoPagesMenu.chatbots.text, to: demoPagesMenu.chatbots.path },
						]}
					/>
					<SubheaderSeparator />
					<span className='text-muted'>{data?.length} items</span>
				</SubHeaderLeft>
				<SubHeaderRight className='!flex !flex-row !w-auto'>
					<Button
						color='dark'
						isLight
						icon='Add'
                        onClick={() => { navigate(`/chatbots/new`); setIsOpenNewModal(true) }}
                    >
						Add New
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='display-4 fw-bold py-3'>All Chatbros</div>
				{isLoading 
					? <div className='h-full w-full flex items-center justify-center'>
						<Spinner isGrow />
					</div>
					: <div className='row'>
						<div className='col-xxl-3 col-xl-4 col-md-6 min-h-[400px]'>
							<Card stretch onClick={() => { navigate(`/chatbots/new`); setIsOpenNewModal(true) }}>
								<CardBody className='d-flex align-items-center justify-content-center'>
									<Button
										color='info'
										size='lg'
										isLight
										className='w-100 h-100 bg-primary-50'
										icon='AddCircle'>
										Add New
									</Button>
								</CardBody>
							</Card>
						</div>
						{data?.map((chatbot:any, key:any) => (
							<div key={chatbot.id} className={`col-xxl-3 col-xl-4 col-md-6 chatbot-${key}`}>
								<GridChatbotItem
									{...chatbot}
									editAction={() => {}}
									deleteAction={() =>{}}
								/>
							</div>
						))}
					</div>
				}
			</Page>
            <NewChatbotModal getChatbots={getChatbots} isOpenNewModal={isOpenNewModal} setIsOpenNewModal={setIsOpenNewModal} />
		</PageWrapper>
	);
};

export default Chatbots;
