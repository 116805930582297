import React, { FC, useRef, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Avatar from "../../../components/Avatar";
import { useClickAway } from "react-use";
//@ts-ignore
import { ChromePicker } from 'react-color';

interface ICardProps {
    formik: any,
    className?: string
}

const CardThemeColors: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    const primaryColorRef = useRef(null);
    const secondaryColorRef = useRef(null);

    const [ showPrimaryColorPicker, setShowPrimaryColorPicker ] = useState(false);
    const [ showSecondaryColorPicker, setShowSecondaryColorPicker ] = useState(false);

    useClickAway(primaryColorRef, () => {
        if (showPrimaryColorPicker) setShowPrimaryColorPicker(false);
    });

    useClickAway(secondaryColorRef, () => {
        if (showSecondaryColorPicker) setShowSecondaryColorPicker(false);
    });

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='ColorLens' iconColor='secondary'>
                    <CardTitle>Theme Colors</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className="pt-0">
                <div className='row g-4 align-items-center'>
                    <div className='col-lg-6 relative'>
                        <FormGroup
                            onClick={() => setShowPrimaryColorPicker(true)}
                            id='primary_color'
                            label='Primary Color (gradient)'
                            className="form-group-color-input"
                            isFloating>
                            <div className={`rounded-lg w-8 h-8 absolute left-3.5 top-3.5`} style={{ backgroundColor: formik.values.primary_color }}></div>
                            <Input
                                placeholder='#FFFFFF'
                                onChange={formik.handleChange}
                                value={formik.values.primary_color}
                                required
                                readOnly
                            />
                        </FormGroup>
                        <div ref={primaryColorRef} className={`absolute overflow-hidden rounded-2xl shadow-xl bottom-[100%] z-[2] hidden ${showPrimaryColorPicker && `!flex`}`}>
                            <ChromePicker 
                                color={formik.values.primary_color}
                                colors={["#2E56A7", "#BB6DE5"]}
                                onChange={(color:any) => formik.setFieldValue('primary_color', color?.hex)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 relative'>
                        <FormGroup
                            onClick={() => setShowSecondaryColorPicker(true)}
                            id='secondary_color'
                            label='Secondary Color (gradient)'
                            className="form-group-color-input"
                            isFloating>
                            <div className={`rounded-lg w-8 h-8 absolute left-3.5 top-3.5`} style={{ backgroundColor: formik.values.secondary_color }}></div>
                            <Input
                                placeholder='#FFFFFF'
                                onChange={formik.handleChange}
                                value={formik.values.secondary_color}
                                required
                                readOnly
                            />
                        </FormGroup>
                        <div ref={secondaryColorRef} className={`absolute overflow-hidden rounded-2xl shadow-xl bottom-[100%] z-[2] hidden ${showSecondaryColorPicker && `!flex`}`}>
                            <ChromePicker 
                                color={formik.values.secondary_color}
                                colors={["#2E56A7", "#BB6DE5"]}
                                onChange={(color:any) => formik.setFieldValue('secondary_color', color?.hex)}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardThemeColors;