import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "./SessionContext";
import Api from "../services/Api";
import showNotification from "../components/extras/showNotification";
import AppSumoWelcomeModal from "../components/AppSumoWelcomeModal";
import AppSumoModal from "../components/AppSumoModal";

const AppSumoContext = React.createContext({
    showAppSumoModal: false,
    setShowAppSumoModal: (value) => void 0,
    showAppSumoWelcomeModal: false,
    setShowAppSumoWelcomeModal: (value) => void 0
});

const AppSumoProvider = ({ children }) => {

    const { user, updateUserData } = useContext(SessionContext);
    const [ showAppSumoWelcomeModal, setShowAppSumoWelcomeModal ] = useState(false);
    const [ showAppSumoModal, setShowAppSumoModal ] = useState(false);

    useEffect(() => {
		const url = new URL(window.location.href);
        const appsumo = url.searchParams.get("appsumo");
		if (appsumo) {
            localStorage.setItem(`appsumo`, appsumo);
            if (window.location.pathname.includes("login")) {
                setShowAppSumoWelcomeModal(true);
            }
		}
    }, []);

	useEffect(() => {
		if (localStorage.getItem(`appsumo`)) {
            if (user?.id) {
                const appsumo = localStorage.getItem(`appsumo`);
                localStorage.removeItem(`appsumo`);
                Api.post(`/subscriptions`, {}, { subscription_token: appsumo }).then((response) => {
                    setShowAppSumoModal(true);
                    updateUserData();
                }).catch((err) => {
                    if (String(err?.message)?.includes(".exists")) {
                        showNotification("AppSumo", `The redemption code doesn't exist. Try clicking the redeem button in AppSumo.`, 'danger');
                    } else {
                        showNotification("AppSumo", `${err}`, 'danger');
                    }
                })
            }
        } 
	}, [user?.subscription, user?.id]);

    return (
        <AppSumoContext.Provider value={{
            showAppSumoModal,
            setShowAppSumoModal,
            showAppSumoWelcomeModal,
            setShowAppSumoWelcomeModal
        }}>
            {children}
            {showAppSumoModal && <AppSumoModal />}
            {showAppSumoWelcomeModal && <AppSumoWelcomeModal />}
        </AppSumoContext.Provider>
    )
}

export { AppSumoContext, AppSumoProvider };