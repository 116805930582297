import React, { useContext, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "./bootstrap/Modal";
import { PricingModalContext } from "../contexts/PricingModalContext";
import Card, { CardBody } from "./bootstrap/Card";
import Icon from "./icon/Icon";
import Button, { ButtonGroup } from "./bootstrap/Button";
import Api, { API_HOST } from "../services/Api";
import { SessionContext } from "../contexts/SessionContext";
import Badge from "./bootstrap/Badge";
import { formatNumber, hasActiveSubscription } from "../helpers/helpers";
import showNotification from "./extras/showNotification";
import Spinner from "./bootstrap/Spinner";

export const plans = [
    {
        id: {
            month: 1,
            year: 2
        },
        price: {
            month: 15,
            year: 120
        },
        name: "Standard Plan",
        items: [
            { name: "1 Chatbro", checked: true },
            { name: "500 Conversations/month", checked: true },
            { name: "50 Data Sources", checked: true },
            // { name: "Embed On Unlimited Websites", checked: true },
            { name: "Watermark: Visible", checked: false },
        ],
        recommended: false,
        icon: "Person"
    },
    {
        id: {
            month: 3,
            year: 4
        },
        price: {
            month: 49,
            year: 384
        },
        name: "Growth Plan",
        items: [
            { name: "5 Chatbros", checked: true },
            { name: "2,000 Conversations/month", checked: true },
            { name: "500 Data Sources", checked: true },
            // { name: "Embed On Unlimited Websites", checked: true },
            { name: "Watermark: Visible", checked: false },
        ],
        recommended: true,
        icon: 'CustomRocketLaunch'
    },
    {
        id: {
            month: 5,
            year: 6
        },
        price: {
            month: 199,
            year: 1548
        },
        name: "Business Plan",
        items: [
            { name: "10 Chatbros", checked: true },
            { name: "10,000 Conversations/month", checked: true },
            { name: "2,000 Data Sources", checked: true },
            // { name: "Embed On Unlimited Websites", checked: true },
            { name: "No Watermark", checked: true },
        ],
        recommended: false,
        icon: 'Work'
    },
    {
        id: {
            month: 7,
            year: 8
        },
        price: {
            month: 999,
            year: 7908
        },
        name: "Enterprise Plan",
        items: [
            { name: "Unlimited Chatbros", checked: true },
            { name: "Unlimited Conversations", checked: true },
            { name: "10,000 Data Sources", checked: true },
            // { name: "Embed On Unlimited Websites", checked: true },
            { name: "No Watermark", checked: true },
        ],
        recommended: false,
        icon: 'CustomFactory'
    }
];

const PricingModal = () => {

    const { user, updateUserData } = useContext<any>(SessionContext);
    const { isOpenPricingModal, setIsOpenPricingModal } = useContext(PricingModalContext);
    const [ billingPeriod, setBillingPeriod ] = useState<'month' | 'year'>(user?.subscription?.items?.[0]?.price?.recurring || 'year');
    const [ isLoadingSubmit, setIsLoadingSubmit ] = useState<number | undefined>(undefined);

    const handleOnClick = (price_id:number) => {
        setIsLoadingSubmit(price_id);
        if (user?.subscription?.id) {
            Api.put(`/subscriptions`, {}, { price_id: price_id, resume: true }).then((response) => {
                showNotification("Plan updated", "Your subscription plan was successfully updated", 'success');
                updateUserData();
                setIsOpenPricingModal(false);
                setIsLoadingSubmit(undefined);
            }).catch((err) => {
                showNotification("Error", err?.message, 'danger');
            })
        } else {
            window.location.href = `${API_HOST}/api/subscriptions/checkout?frontend_url=${encodeURI(window.location.href)}&price_id=${price_id}&token=${localStorage.getItem("token")}`
        }
    }

    return (
        <Modal
            setIsOpen={setIsOpenPricingModal}
            isOpen={isOpenPricingModal}
            fullScreen
            titleId='pricing-modal'
        >
            <ModalHeader isLight className="absolute z-[1] w-full left-0 top-0 !bg-transparent hidden lg:flex bg-dark w-full text-white" setIsOpen={setIsOpenPricingModal}>
                <ModalTitle id='transfer-modal'>Pricing</ModalTitle>
            </ModalHeader>
            <ModalBody className='p-0 lg:p-inherit h-100 d-flex flex-col align-items-center bg-dark w-full' style={{ backgroundImage: 'url(/images/footer-bg-shapes.svg', backgroundSize: '200%', backgroundPosition: '100px 50%', backgroundRepeat: 'no-repeat'}}>
                <div className='row lg:w-full h-full items-center !m-0'>
                    <div className='col-lg-5 mx-auto !p-0 lg:p-inherit shadow-3d-container h-full lg:h-auto text-center text-white'>
                        <div className='display-4 fw-bold py-3'>Simple Pricing</div>
                        <div className='text-3xl pb-3'>Start growing your brand with <span className="text-gradient-primary">Chatbros</span> now!</div>
                        <br />
                        <div className="relative inline-block w-auto pb-3">
                            <ButtonGroup>
                                <Button color='primary' isOutline={billingPeriod == 'year'} onClick={() => setBillingPeriod('month')}>
                                    Monthly
                                </Button>
                                <Button color='primary' isOutline={billingPeriod == 'month'} onClick={() => setBillingPeriod('year')}>
                                    Annualy
                                    <Badge color="danger" className="ml-3">SAVE 35%</Badge>
                                </Button>
                            </ButtonGroup>
                            {/* <Badge color="danger" className="absolute right-0 top-0 translate-x-[100%] -translate-y-[100%] text-sm p-2 px-3">SAVE 35%</Badge> */}
                        </div>
                    </div>
                </div>
                <div className="p-6 pt-0 lg:p-10 w-full">
                    <div className="row lg:w-full h-full items-center">
                        {plans.map((item, key) => (
                            <div className="col-lg-3">
                                <Card
                                    className={`${item.recommended && !hasActiveSubscription(user) ? 'bg-lo25-primary' : 'bg-[#242731]'} !text-white`}
                                >
                                    <CardBody className={`${item.recommended && !hasActiveSubscription(user) && `!pt-20`} ${item?.id?.[billingPeriod] == user?.subscription?.items?.[0]?.price?.id && `opacity-[1]`}`}>
                                        {item.recommended && !hasActiveSubscription(user) && 
                                            <span className='text-center position-absolute top-4 left-4 right-4 border border-primary border-2 text-primary fw-bold px-2 py-1 rounded-full lead text-uppercase'>
                                                MOST POPULAR
                                            </span>
                                        }
                                        {item?.id?.[billingPeriod] == user?.subscription?.items?.[0]?.price?.id && `opacity-[0.5]` && 
                                            <Icon 
                                                color="success"
                                                icon="CheckCircle"
                                                size="2x"
                                                className="absolute top-6 right-6"
                                            />
                                        }
                                        <div className='row pt-3 gap-6 text-center relative'>
                                            <div className='col-12 flex items-center justify-center w-full'>
                                                <Icon icon={item.icon} size='4x' color={item.recommended && !hasActiveSubscription(user) ? 'info' : 'info'} />
                                            </div>
                                            <div className='col-12'>
                                                <h2 className="text-center text-xl font-semibold">{item.name}</h2>
                                            </div>
                                            <div className='col-12'>
                                                <h3 className='display-4 fw-bold'>
                                                    <span className='display-5 fw-bold'>$</span>{formatNumber(item?.price?.[billingPeriod] / (billingPeriod == "year" ? 12 : 1))}
                                                    <span className='display-6'>/{billingPeriod == 'month' ? `mo` : `mo`}</span>
                                                </h3>
                                                {billingPeriod == 'year' && <span className="text-gray-400">Billed Yearly: ${formatNumber(item?.price?.[billingPeriod])}</span>}
                                            </div>
                                            <div className='col-12 flex flex-col gap-1'>
                                                {item.items.map((features, key) => (
                                                    <div className={`!m-0 flex items-center gap-2 justify-center w-full text-lg ${!features.checked && 'text-gray-600'}`}>
                                                        <Icon icon={features.checked ? 'Done Outline' : 'Close'} color={features.checked ? 'success' : 'danger'} />
                                                        {features.name}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='col-12 mt-2'>
                                                <Button
                                                    color={item?.id?.[billingPeriod] == user?.subscription?.items?.[0]?.price?.id ? 'dark' : 'primary'}
                                                    className='w-100 py-3'
                                                    size='lg'
                                                    isDisable={item?.id?.[billingPeriod] == user?.subscription?.items?.[0]?.price?.id || isLoadingSubmit !== undefined}
                                                    target={user?.subscription?.id 
                                                        ? '_blank'
                                                        : undefined
                                                    }
                                                    onClick={() => handleOnClick(item?.id?.[billingPeriod])}
                                                >
                                                    {isLoadingSubmit == item?.id?.[billingPeriod] 
                                                        ? <>
                                                            <Spinner isSmall /> &nbsp; 
                                                            Loading...
                                                        </> : <>
                                                            {item?.id?.[billingPeriod] == user?.subscription?.items?.[0]?.price?.id ? 'Actual Plan' : 'Select Plan'}
                                                        </>
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default PricingModal;