import { FC, useEffect, useRef, useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { formatNumber } from "../../../helpers/helpers";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import dayjs from "dayjs";
import Badge from "../../../components/bootstrap/Badge";
import Button from "../../../components/bootstrap/Button";
import Icon from "../../../components/icon/Icon";
import Checks from "../../../components/bootstrap/forms/Checks";
import Tooltips from "../../../components/bootstrap/Tooltips";

interface ICardProps {
    formik: any,
    source?: any,
    index: number,
}

interface IStatusBadgeProps {
    status: any,
    status_message?: any
}

export const StatusBadge:FC<IStatusBadgeProps> = ({ status, status_message = undefined }) => {

    const icon = 
        status == "pending" ? 'AccessTime'
        : status == "processing" ? 'AccessTime'
        : status == "processed" ? 'Check' 
        : status == "error" ? 'Error'
        : "";

    return (
        <Tooltips title={status_message} placement="left">
            <Badge 
                color={status == "pending" ? 'dark' : status == "processed" ? 'success' : status == "error" ? 'danger' : 'info'} 
                isLight={status != "processed"}
                className="!text-xs flex items-center gap-1"
            >
                {!!icon && <Icon icon={icon} color={status == "pending" ? 'dark' : status == "processed" ? 'light' : status == "error" ? 'danger' : 'info'} />}
                <span className="capitalize">{(status || "New")}{!!status_message && status == "error" && <>: <span className="normal-case font-normal">{status_message}</span></>}</span>
            </Badge>
        </Tooltips>
    )
}

const TextKnowledge:FC<ICardProps> = ({
    formik, 
    source,
    index
}) => {

    const maxLabelLength = 100;
    const maxLength = 10000;
    const [ isOpen, setIsOpen ] = useState(!source?.content ? true : false);
    const textRef = useRef<any>();

    const deleteTextKnowledge = () => {
        let _sources = (formik?.values?.sources || []);
        if (source?.id) {
            _sources = _sources.map((e:any, key:number) => {
                if (key == index) {
                    e.action = 'delete'; 
                }
                return e;
            });
        } else {
            _sources = _sources.filter((e:any, key:number) => key != index);
        }
        formik.setFieldValue('sources', _sources);
    }

    useEffect(() => {
        if (source?.content?.length > maxLength) {
            setText(source?.content.substring(0, maxLength));
        }
    }, [source?.content?.length]);

    useEffect(() => {
        if (source?.label?.length > maxLength) {
            setLabel(source?.label.substring(0, maxLength));
        }
    }, [source?.label?.length]);

    const setLabel = (label:any) => {
        let _sources = (formik?.values?.sources || []);
        _sources = _sources.map((e:any, key:number) => {
            if (key == index) {
                e.label = label; 
                e.action = 'update';
            }
            return e;
        });
        formik.setFieldValue('sources', _sources);
    }

    const setText = (text:any) => {
        let _sources = (formik?.values?.sources || []);
        _sources = _sources.map((e:any, key:number) => {
            if (key == index) {
                e.content = text; 
                e.action = 'update';
            }
            return e;
        });
        formik.setFieldValue('sources', _sources);
    }

    const setSelected = (value:boolean) => {
        let _sources = (formik?.values?.sources || []);
        _sources = _sources.map((e:any, key:number) => {
            if (key == index) {
                e.selected = value; 
                e.action = 'update';
            }
            return e;
        });
        formik.setFieldValue('sources', _sources);
    }

    // useEffect(() => {
    //     if (textRef.current && isOpen) {
    //         textRef.current.focus();
    //         setTimeout(function(){ textRef.current.selectionStart = textRef.current.selectionEnd = 10000; }, 0);
    //     }
    // }, [isOpen]);

    useEffect(() => {
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
    }, [source?.content, isOpen]);

    return (
        <div>
            <FormGroup
                formText={<>&nbsp;&nbsp; Length: {formatNumber(source?.content?.length)} / {formatNumber(maxLength)}</>}
            >
                <div className="form-control flex flex-col gap-0 !h-full overflow-y-auto overflow-hidden w-full !p-0 relative overflow-hidden">
                    <div className="flex justify-between items-center font-[500] text-[#6c757d] mb-1 px-3 pt-2.5 pb-1 text-sm">
                        <span className="flex items-center gap-0 pl-0.5">
                            <Checks
                                className="cursor-pointer text-lg"
                                labelClassName="w-full overflow-hidden"
                                color="info"
                                checked={!!source?.selected}
                                onChange={(e:any) => setSelected(e.target.checked)}
                            />
                            {source?.label && <h6 className="text-lg font-semibold text-gray-700 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>{source?.label}</h6>}
                        </span>
                        <div className="flex gap-2 items-center">
                            {!!source?.selected && <StatusBadge status_message={source?.status_message} status={source?.status} />}
                            <Icon icon="Delete" size="md" color="danger" className="cursor-pointer" onClick={deleteTextKnowledge} />
                        </div>
                    </div>
                    {isOpen 
                        ? <>
                            <Input 
                                placeholder="Text Document Title"
                                value={source?.label}
                                className={`!shadow-none !overflow-hidden !text-xl`}
                                onChange={(e:any) => setLabel(e.target.value)}
                                autoFocus
                            />
                            <Textarea
                                innerRef={textRef}
                                placeholder="Enter text..."
                                value={source?.content}
                                className={`!shadow-none !overflow-hidden !font-normal`}
                                onChange={(e:any) => setText(e.target.value)}
                                autoFocus
                            />
                        </>
                        : <div className="px-3 pt-0 pb-2 overflow-hidden">
                            <p className="overflow-hidden line-clamp-2 text-muted font-normal" onClick={() => setIsOpen(true)}>
                                {source?.content ? source?.content : <i className="text-muted">Empty</i>}
                            </p>
                        </div>
                    }
                    <Button onClick={() => setIsOpen(!isOpen)} color="dark" isLight size="sm" icon={isOpen ? `VisibilityOff` : `Edit`} className="mt-2 rounded-t-none">
                        {isOpen 
                            ? `Hide`
                            : `Edit / View`
                        }
                    </Button>
                </div>
            </FormGroup>
        </div>
    )
}

export default TextKnowledge;