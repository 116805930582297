import React, { useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import { pageLayoutTypesPagesMenu } from '../../menu';
import Popovers from '../../components/bootstrap/Popovers';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../../components/bootstrap/Breadcrumb';
import ConversationsChart from './components/ConversationsChart';
import ConversationsCard from './components/ConversationsCard';
import VisitsCard from './components/VisitsCard';
import ConversationsVisitsRatioCard from './components/ConversationsVisitsRatioCard';
import WelcomeMessage from './components/WelcomeMessage';
import Api from '../../services/Api';
import dayjs from 'dayjs';
import Spinner from '../../components/bootstrap/Spinner';
import SupportCard from './components/SupportCard';
import SubscriptionCard from './components/SubscriptionCard';

export const formatSeries = (data:any, dateStart:Date, dateEnd:Date) => {
	var _series = [];
	var _labels = [];
	for (var i = dateStart.getTime(); i < dateEnd.getTime(); i += 1000 * 3600 * 24) {
		_labels.push(new Date(i).toLocaleDateString());
		_series.push(data?.filter((e:any) => e?.day == new Date(i).toISOString().split('T')[0])?.[0]?.count || 0);
	}
	return { series: _series, labels: _labels };
}

const Dashboard = () => {

	const dateEnd = dayjs().endOf('day').toDate();
	const dateStart = dayjs().subtract(1, 'month').toDate();

	const [isLoading, setIsLoading] = useState(true);
	const [conversationsCount, setConversationsCount] = useState<any>();
	const [conversations, setConversations] = useState<any>();
	const [visitsCount, setVisitsCount] = useState<any>();
	const [visits, setVisits] = useState<any>();

	const getData = () => {
		setIsLoading(true);
		Promise.all([
			Api.get(`/conversations?page[size]=1&filter[created_at_after]=${dateStart.toISOString()}&filter[created_at_before]=${dateEnd.toISOString()}`),
			Api.get(`/conversations?group_by=created_at&filter[created_at_after]=${dateStart.toISOString()}&filter[created_at_before]=${dateEnd.toISOString()}`),
			Api.get(`/visits?page[size]=1&filter[created_at_after]=${dateStart.toISOString()}&filter[created_at_before]=${dateEnd.toISOString()}`),
			Api.get(`/visits?group_by=created_at&filter[created_at_after]=${dateStart.toISOString()}&filter[created_at_before]=${dateEnd.toISOString()}`),
		]).then(([_conversationsCount, _conversations, _visitsCount, _visits]) => {
			setConversationsCount(_conversationsCount?.meta?.total);
			setConversations(formatSeries(_conversations?.data, dateStart, dateEnd));
			setVisitsCount(_visitsCount?.meta?.total);
			setVisits(formatSeries(_visits?.data, dateStart, dateEnd));
		}).catch(() => {
			
		}).finally(() => {
			setIsLoading(false);
		});
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<PageWrapper title='Dashboard Page'>
			<Page className='h-100'>
				{isLoading
					? <div className='h-full w-full flex items-center justify-center'>
						<Spinner isGrow />
					</div>
					: <div className='row'>
						<div className='col-lg-12'>
							<WelcomeMessage />
						</div>
						<div className='col-lg-6'>
							<SupportCard />
						</div>
						<div className='col-lg-6'>
							<SubscriptionCard />
						</div>
						<div className='col-lg-12'>
							<ConversationsChart conversations={conversations} visits={visits} />
						</div>
						<div className='col-lg-4'>
							<ConversationsCard conversationsCount={conversationsCount} conversations={conversations} />
						</div>
						<div className='col-lg-4'>
							<VisitsCard visitsCount={visitsCount} visits={visits} />
						</div>
						<div className='col-lg-4'>
							<ConversationsVisitsRatioCard conversationsCount={conversationsCount} conversations={conversations} visitsCount={visitsCount} visits={visits} />
						</div>
					</div>
				}
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
