import React from "react";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Avatar from "../../../components/Avatar";
import { getColorNameWithIndex } from "../../../common/data/enumColors";
import Icon from "../../../components/icon/Icon";
import useDarkMode from "../../../hooks/useDarkMode";
import { getFirstLetter } from "../../../helpers/helpers";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import dayjs from "dayjs";

export const camelCaseToWords = (s:string) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getIconByFieldName = (key:string) => {
    if (String(key).toLowerCase().includes("created_at")) {
        return 'CalendarToday'
    }
    if (String(key).toLowerCase().includes("name")) {
        return 'Person';
    }
    if (String(key).toLowerCase().includes("email")) {
        return 'Mail';
    }
    if (String(key).toLowerCase().includes("phone")) {
        return 'Phone';
    }
    if (String(key).toLowerCase().includes("address")) {
        return 'Place';
    }
    if (String(key).toLowerCase().includes("country")) {
        return 'Flag'
    }
    if (String(key).toLowerCase().includes("city")) {
        return 'LocationCity'
    }
    if (String(key).toLowerCase().includes("title")) {
        return 'Work'
    }
    if (String(key).toLowerCase().includes("company")) {
        return 'HomeWork'
    }
    if (String(key).toLowerCase().includes("birth")) {
        return 'Cake'
    }
    if (String(key).toLowerCase().includes("car")) {
        return 'DirectionsCar'
    }
    if (String(key).toLowerCase().includes("gender")) {
        return 'Transgender'
    }
    if (String(key).toLowerCase().includes("budget")) {
        return 'AttachMoney'
    }
  
    return 'Circle';
}

const ViewContactModal = (props: any) => {

    const {
        id,
        leads,
        image,
        socialNetwork,
        created_at,
        metadata,
        external_name,
        isOpenViewModal,
        setIsOpenViewModal,
    } = props;

	const { darkModeStatus } = useDarkMode();

    if (!id) return <></>;

    return (
        <Modal
            setIsOpen={setIsOpenViewModal}
            isOpen={isOpenViewModal}
            titleId='contact-view-modal'
            className="!my-0"
            contentClassName="!bg-transparent !shadow-none !h-[100%]"
            dialogClassName="!h-[100%] !my-0"
        >
            <div className="hidden"></div>
            <ModalBody className='p-0 lg:p-inherit h-100 d-flex justify-center items-center align-items-center !bg-transparent w-full'>
                <Card className='shadow-3d-primary relative'>
                    <CardBody className="max-h-[90vh] overflow-y-auto">
                        <ModalHeader className="absolute left-0 top-2 bg-transparent w-[98%] text-white z-[10]" setIsOpen={setIsOpenViewModal}>
                            <ModalTitle id='contact-view-modal'>View Contact</ModalTitle>
                        </ModalHeader>
                        <div className='row g-5 py-3'>
                            <div className='col-12 d-flex justify-content-center'>
                                {image?.url 
                                    ? <Avatar src={image?.url} className='rounded-full !h-36 !w-36' />
                                    : <div
                                        className={`bg-l${
                                            darkModeStatus
                                                ? 'o25'
                                                : '25'
                                        }-${getColorNameWithIndex(
                                            id,
                                        )} text-${getColorNameWithIndex(
                                            id,
                                        )} rounded-2 d-flex align-items-center justify-content-center !h-36 !w-36 !rounded-full`}>
                                        <span className='fw-bold !text-3xl'>
                                            {getFirstLetter(external_name || leads?.name || leads?.email || "c b")}
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-12'>
                                <div className='row g-4'>
                                    {(leads?.additional_information || leads?.additionalInformation) && (
                                        <div className='col-12 relative z-[2]'>
                                            <Alert
                                                icon='AutoAwesome'
                                                isLight
                                                color='primary'
                                                borderWidth={0}
                                                className='shadow-3d-primary !mb-8'>
                                                <AlertHeading tag='h4' className='h4 !text-base !font-bold !-mb-0'>
                                                    AI Additional Information
                                                </AlertHeading>
                                                <span>{leads?.additional_information || leads?.additionalInformation}</span>
                                            </Alert>
                                        </div>
                                    )}
                                    {!!created_at && 
                                        <div className='col-12'>
                                            <div className='d-flex align-items-center'>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon={getIconByFieldName('created_at')}
                                                        size='2x'
                                                        color='primary'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ml-4'>
                                                    <div className='text-muted !text-sm'>
                                                        Created At
                                                    </div>
                                                    <div className='fw-bold !text-lg mb-0'>
                                                        {dayjs(created_at).toDate().toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {Object.entries(leads).filter(([key, value]) => key != "additional_information" && key != "additionalInformation" && !!String(value)?.trim()).map(([key, value]) => (
                                        <>{value &&
                                            <div className='col-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon
                                                            icon={getIconByFieldName(key)}
                                                            size='2x'
                                                            color='primary'
                                                        />
                                                    </div>
                                                    <div className='flex-grow-1 ml-4'>
                                                        <div className='text-muted !text-sm'>
                                                            {camelCaseToWords(key)}
                                                        </div>
                                                        <div className='fw-bold !text-lg mb-0'>
                                                            {value as any}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }</>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default ViewContactModal;