export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const validateEmail = (email: string) => {
	return String(email)
	  .toLowerCase()
	  .match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	  );
};

export const getFullDomain = () => {
	return window.location.protocol + '//' + window.location.host;
}

export const removeProtocol = (website:string) => {
	return String(website).toLowerCase().replaceAll("http://", "").replaceAll("https://", "");
}

export const isValidHttpsUrl = (url:string) => {
	try {
		const newUrl = new URL(url);
		return newUrl.protocol === 'https:';
	} catch (err) {
		return false;
	}
  }

export const formatNumber = (number:any, decimals = 0, checkInfinity = false) => {
	if (number === null && checkInfinity) {
		return "∞";
	}
	if (isNaN(number)) {
		return 0;		
	}
    return Number(number).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
}



export const messageFormatter = (str:any) => {

    function linkReplacer(matched:any) {
        let withProtocol = matched
        if (!withProtocol.startsWith("http")) {
            withProtocol = "https://" + matched
        }
        const newStr = `<a class="font-semibold underline" target="_blank" href="${withProtocol}">${matched}</a>`
        return newStr
    }

    function imageReplacer(matched:any) {
        let withProtocol = matched
        if (!withProtocol.startsWith("http")) {
            withProtocol = "https://" + matched
        }
        const newStr = `<div><a target="_blank" href="${withProtocol}"><img class="rounded-2xl mt-2 max-w-[300px] w-full" src="${withProtocol}" /></a></div>`;
        return newStr
    }

    const imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg))/i
    if (str.match(imageRegex)) {
        [...new Set(str.match(imageRegex)) as any].map((imgUrl) => {
            const imgHTML = imageReplacer(imgUrl);
            str = str.replace(imgUrl, '');
            str = str + imgHTML;
        })
    }

    const linkRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g
    str = str.replace(linkRegex, linkReplacer);

    return str;
}

export function hasActiveSubscription(user:any) {
	if (user?.subscription?.stripe_status == "active" || user?.subscription?.stripe_status == "trialing") {
		return true;
	}
	return false;
}

export default function capitalize(value:any) {
    return typeof value !== 'string'
        ? ''
        : value?.charAt(0)?.toUpperCase() + value?.slice(1)
}