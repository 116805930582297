import React, { FC, useEffect } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Textarea from "../../../components/bootstrap/forms/Textarea";

interface ICardProps {
    formik: any,
    className?: string
}

const CardSalesperson: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    const maxLength = 1000;

    if (!formik) return <></>;

    useEffect(() => {
        if (formik?.values?.salesperson_instruction?.length > maxLength) {
            formik.setFieldValue('salesperson_instruction', formik?.values?.salesperson_instruction.substring(0, maxLength));
        }
    }, [formik?.values?.salesperson_instruction]);

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel>
                    <CardTitle className="flex items-center gap-2.5">
                        <span className="text-3xl">👨‍💼</span> 
                        <div className="flex flex-col gap-2">
                            <span>Sale Instructions</span>
                            <CardSubTitle className="font-normal">Instruct your Chatbro on how you manage your sale process</CardSubTitle>
                        </div>
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-12'>
                        <FormGroup
                            id='salesperson_instruction'
                            label='Sale Instructions (optional)'
                            formText={`${formik.values.salesperson_instruction?.length || 0} / ${maxLength} - Please provide specific instructions on how your Chatbro should handle sales for the company. Include details on product offerings, product links (URLs), pricing strategies, customer engagement approaches, and any other relevant guidance to optimize the sales process.`}
                            isFloating>
                            <Textarea
                                ariaLabel='Sale Instructions (optional)'
                                onChange={formik.handleChange}
                                value={formik.values.salesperson_instruction}
                                style={{ minHeight: "70px" }}
                            />
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardSalesperson;