import React, { useEffect, useRef, useState } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Avatar from "../../../components/Avatar";
import { getColorNameWithIndex } from "../../../common/data/enumColors";
import Icon from "../../../components/icon/Icon";
import useDarkMode from "../../../hooks/useDarkMode";
import { getFirstLetter } from "../../../helpers/helpers";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import dayjs from "dayjs";
import Spinner from "../../../components/bootstrap/Spinner";
import ChatbotPreview from "../../chatbot_preview/ChatbotPreview";

const PreviewChatbroModal = (props: any) => {

    const {
        setIsOpenPreviewModal,
        isOpenPreviewModal,
        formik
    } = props;

    const [ rand, setRand ] = useState(0);
    const [ isLoadingIframe, setIsLoadingIframe ] = useState(true);
    const iframeRef = useRef<any>();

    useEffect(() => {
        window.addEventListener('message', (e) => {
            iframeRef.current?.contentWindow?.postMessage(e.data);
        });
    }, [isOpenPreviewModal]);

    useEffect(() => {
        if (isOpenPreviewModal) {
            setRand(Math.random());
        }
    }, [isOpenPreviewModal]);

    if (!formik) return <></>;

    return (
        <Modal
            setIsOpen={setIsOpenPreviewModal}
            isOpen={isOpenPreviewModal}
            titleId='contact-view-modal'
            className="!my-0"
            contentClassName="!bg-transparent !shadow-none !h-[100%]"
            dialogClassName="!h-[100%] !my-0"
        >
            <div className="hidden"></div>
            <ModalBody className='w-full p-0 lg:p-inherit h-100 d-flex justify-center items-center align-items-center !bg-transparent w-full'>
                <Card className='shadow-3d-primary relative w-[450px] h-[650px] max-h-[90%] max-w-[95%]'>
                    <CardHeader>
                        <CardLabel icon="Visibility" iconColor="primary">
                            <CardTitle tag='div' className='h5'>
                                Chatbro Preview
                            </CardTitle>
                            <CardSubTitle tag='div' className='h6'>
                                Save your changes to update the preview
                            </CardSubTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody className="pb-0 px-0 lg:!pb-4 lg:!px-4 !pt-0 h-full flex-1 overflow-y-auto flex w-full items-center justify-center">
                        {isLoadingIframe && <Spinner isGrow />}
                        {!!isOpenPreviewModal &&
                            // <ChatbotPreview external_id={formik?.values?.external_id} />
                            <iframe 
                                ref={iframeRef}
                                key={rand}
                                onLoad={() => setIsLoadingIframe(false)}
                                src={`/preview.html?external_id=${formik?.values?.external_id}&rand=${rand}&domain=${process.env.REACT_APP_WIDGET_URL}`}
                                className={`w-full h-full rounded-3xl overflow-hidden shadow-[0_1.6rem_3rem_rgba(0,0,0,0.1)] ${isLoadingIframe && `!hidden`}`}
                            />
                        }
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default PreviewChatbroModal;