import React, { FC, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, leadFieldOptions, toneOptions } from "./NewChatbotModal";
import Icon from "../../../components/icon/Icon";

interface ICardProps {
    formik: any,
    className?: string
}

const CardLeads: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    const [ customFieldValue, setCustomFieldValue ] = useState("");

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel>
                    <CardTitle className="flex items-center gap-2.5">
                        <span className="text-4xl">🎯</span> 
                        <div className="flex flex-col gap-2">
                            <span>Lead Fields</span>
                            <CardSubTitle className="font-normal">Click on the unselected fields or write your own custom fields</CardSubTitle>
                        </div>
                    </CardTitle>
                </CardLabel>
                <CardActions>
                    <Badge color={'warning'}>
                        Required
                    </Badge>
                </CardActions>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-12'>
                        <FormGroup
                            id='type'
                            label='Selected fields'
                            isFloating>
                            <div className="form-control flex flex-wrap gap-1.5 !h-full max-h-[200px] overflow-y-auto !pt-8">
                                {formik.values.lead_fields?.map((field:any, key:any) => (
                                    <Badge
                                        key={key}
                                        isLight
                                        color={'primary'}
                                        className='px-3 py-2 cursor-pointer transition-all hover:scale-[1.04] flex items-center gap-1.5'
                                        onClick={() => formik.setFieldValue('lead_fields', formik.values.lead_fields?.filter((e:any) => e != field))}
                                    >
                                        {field}
                                        <Icon icon="Close" />
                                    </Badge>
                                ))}
                                <Badge
                                    isLight
                                    color={'dark'}
                                    className='!bg-transparent flex relative px-0 py-2 cursor-pointer transition-all flex items-center gap-1.5'
                                    >
                                    {/* {customFieldValue && <Icon icon="CheckCircle" color="success" className="text-lg" />} */}
                                    <input
                                        type="text" 
                                        className="bg-transparent w-auto !outline-0 !border-0 px-0"
                                        placeholder="Write a custom field..."
                                        value={customFieldValue}
                                        onChange={(e) => setCustomFieldValue(e.target.value)}
                                        onKeyDown={(e) => { 
                                            if (e.key == "Enter") { 
                                                e.preventDefault();
                                                e.stopPropagation();
                                                formik.setFieldValue('lead_fields', [...(formik.values.lead_fields || []), customFieldValue]);
                                                setCustomFieldValue("");
                                            }
                                            if (e.key == "Backspace" && customFieldValue == "") {
                                                formik.setFieldValue('lead_fields', formik.values.lead_fields?.slice(0, -1));
                                            }
                                        }}
                                    />
                                </Badge>
                            </div>
                        </FormGroup>
                    </div>
                    <div className='col-12'>
                        <FormGroup
                            id='type'
                            label='Unselected fields'
                            isFloating>
                            <div className="form-control flex flex-wrap gap-1.5 !h-full max-h-[200px] overflow-y-auto !pt-8">
                                {leadFieldOptions.filter((e:any) => !formik.values.lead_fields?.includes(e?.id)).map((field, key) => (
                                    <Badge
                                        // isLight
                                        color={'dark'}
                                        className='px-3 py-2 cursor-pointer transition-all hover:scale-[1.04]'
                                        onClick={() => formik.setFieldValue('lead_fields', [...(formik.values.lead_fields || []), field.id])}
                                    >
                                        {field.id}
                                    </Badge>
                                ))}
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardLeads;