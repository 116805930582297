import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import { PricingModalContext } from "../../../contexts/PricingModalContext";
import { plans } from "../../../components/PricingModal";
import { formatNumber, hasActiveSubscription } from "../../../helpers/helpers";
import dayjs from "dayjs";

const SubscriptionCard = () => {

    const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);
    
    if (hasActiveSubscription(user)) {
        return (        
            <Card stretch className="bg-primary text-white">
                <CardBody className="d-flex flex-col align-items-center !gap-8 !px-10 w-full">
                    <div className="w-full flex-1 flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <Icon 
                                className="text-4xl"
                                icon={plans.filter((e) => e.id.month == user?.subscription?.items?.[0]?.price.id || e.id.year == user?.subscription?.items?.[0]?.price.id)?.[0].icon}
                            />
                            <div>
                                <div className="fs-4 fw-bold capitalize">{user?.subscription?.items?.[0]?.product?.name} Plan</div>
                                <small className="text-gray-200 text-base block -mt-1">Renews {dayjs(user?.balance?.conversations_renews_at).fromNow()}</small>
                            </div>
                        </div>
                        {plans.filter((e) => e.id.month == user?.subscription?.items?.[0]?.price.id || e.id.year == user?.subscription?.items?.[0]?.price.id)?.[0].name != plans?.[plans?.length - 1]?.name && <Button className="hidden lg:inline-block" icon="AutoAwesome" onClick={() => setIsOpenPricingModal(true)} color="primary" isLight>Upgrade</Button>}
                    </div>
                    <div className="w-full flex-shrink-0 flex justify-between !gap-8 flex-wrap">
                        <div className="flex flex-col items-center gap-1">
                            <h1 className="font-bold !text-4xl text-slate-50/70 flex items-center gap-2">{user?.balance?.chatbots_spent}<small>/</small>{formatNumber(user?.balance?.chatbots_allowed, 0, true)}</h1>
                            <span className="text-lg">Chatbros</span>
                        </div>
                        <div className="flex flex-col items-center gap-1">
                            <h1 className="font-bold !text-4xl text-slate-50/70 flex items-center gap-2">{formatNumber(user?.balance?.conversations_spent)} / {formatNumber(user?.balance?.conversations_allowed, 0, true)}</h1>
                            <span className="text-lg">Conversations</span>
                        </div>
                        <div className="flex flex-col items-center gap-1">
                            <h1 className="font-bold !text-4xl text-slate-50/70 flex items-center gap-2">{formatNumber(user?.balance?.chatbot_sources_spent)} / {formatNumber(user?.balance?.chatbot_sources_allowed, 0, true)}</h1>
                            <span className="text-lg">Data Sources</span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    } else {
        return ( 
            <Card stretch className="bg-primary text-white">
                <CardBody className="flex flex-col-reverse lg:flex-row lg:justify-start justify-center items-center !gap-8 !px-10 w-full">
                    <div className="flex-1 text-center lg:!text-left">
                        <div className="fs-4 fw-bold">Subscribe to Supercharge Your Chatbros!</div>
                        <div className="mt-2">Get the most out of Chatbros with our subscription plans. Enjoy unlimited access and exclusive features. Upgrade today!</div>
                        <Button icon="AutoAwesome" onClick={() => setIsOpenPricingModal(true)} color="primary" className="mt-3" isLight>View Pricing Plans</Button>
                    </div>
                    <div className="flex-shrink-0 lg:text-left text-center">
                        <span className="text-8xl">🚀</span>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default SubscriptionCard;