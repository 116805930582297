import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	className?: string;
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ className = "", width, height }) => {
	return (
		<img src="/images/logo.png" className={`w-full object-center ${className}`} />
	);
};
Logo.propTypes = {
	className: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: undefined,
	height: undefined,
};

export default Logo;
