import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { demoPagesMenu } from '../../menu';
import Card, { CardBody } from '../../components/bootstrap/Card';
import { getFirstLetter, priceFormat } from '../../helpers/helpers';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../components/PaginationButtons';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import Input from '../../components/bootstrap/forms/Input';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import useSortableData from '../../hooks/useSortableData';
import InputGroup, { InputGroupText } from '../../components/bootstrap/forms/InputGroup';
import Popovers from '../../components/bootstrap/Popovers';
import { getColorNameWithIndex } from '../../common/data/enumColors';
import useDarkMode from '../../hooks/useDarkMode';
import Spinner from '../../components/bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';
import Api, { API_HOST } from '../../services/Api';
import Avatar from '../../components/Avatar';
import ViewContactModal from './components/ViewContactModal';
const { getCode, getName } = require('country-list');


const Contacts = () => {
	const { darkModeStatus } = useDarkMode();

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(30);
    const [items, setItems] = useState<any>();
    const [metadata, setMetadata] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenViewModal, setIsOpenViewModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState<any>();
    const [isLoadingExport, setIsLoadingExport] = useState(false);
    const [chatbots, setChatbots] = useState<any>([]);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
            chatbot_id: '',
            selectionRange: {
                startDate: dayjs().subtract(1, 'month').toDate(),
                endDate: new Date(),
                key: 'selection',
            }
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
            getLeads();
		},
	});

    const getLeads = (url = null) => {
        setIsLoading(true);
        Api.get(url || `/conversations?include=image,socialNetwork,socialNetwork.chatbot&sort=-id&filter[leads]=not_null${formik?.values?.selectionRange?.startDate ? `&filter[created_at_after]=${new Date(formik?.values?.selectionRange?.startDate).toISOString()}` : ``}${formik?.values?.selectionRange?.endDate ? `&filter[created_at_before]=${new Date(formik?.values?.selectionRange?.endDate).toISOString()}` : ``}${formik?.values?.chatbot_id ? `&filter[socialNetwork.chatbot_id]=${formik?.values?.chatbot_id}` : ``}`).then(({ data: items, meta, links }) => {
            setItems(items);
            setMetadata({...meta, links: links });
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false);
        });
    }

    const exportContacts = () => {
        setIsLoadingExport(true);
        var handle = window.open(`${API_HOST}/api/conversations?token=${localStorage.getItem('token')}&export=1&sort=-id&filter[leads]=not_null${formik?.values?.selectionRange?.startDate ? `&filter[created_at_after]=${new Date(formik?.values?.selectionRange?.startDate).toISOString()}` : ``}${formik?.values?.selectionRange?.endDate ? `&filter[created_at_before]=${new Date(formik?.values?.selectionRange?.endDate).toISOString()}` : ``}${formik?.values?.chatbot_id ? `&filter[socialNetwork.chatbot_id]=${formik?.values?.chatbot_id}` : ``}`)
        var timer = setInterval(function() { 
            if(handle?.closed) {
                clearInterval(timer);
                setIsLoadingExport(false);
            }
        }, 1000);
    }

    const changePage = (pageNumber:number) => {
        if (pageNumber - 1 == currentPage) {
            getLeads(metadata?.links?.next);
        }
        if (pageNumber + 1 == currentPage) {
            getLeads(metadata?.links?.prev);
        }
        if (pageNumber - 1 > currentPage) {
            getLeads(metadata?.links?.last);
        }
        if (pageNumber + 1 < currentPage) {
            getLeads(metadata?.links?.first);
        }
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        Api.get(`/chatbots`).then(({ data: chatbots }) => {
            setChatbots(chatbots);
        }).catch((err) => {});
    }, []);

    useEffect(() => {
        getLeads();
    }, []);

	return (
		<PageWrapper title={demoPagesMenu.contacts.text}>
			<SubHeader>
				<SubHeaderLeft>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						id='searchInput'
						type='search'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search contact...'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
                    {/* Chatbros */}
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								icon='SmartToy'
								color='dark'
								isLight
								className='position-relative'
								aria-label='Filter'>
                                Chatbros{!!formik?.values?.chatbot_id && `: ${chatbots?.find((e:any) => e.id == formik?.values?.chatbot_id)?.company_name}`}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd className='!p-0 overflow-hidden'>
							<div className='flex flex-col !p-0 !py-2 !pt-3'>
                                {chatbots?.map((item:any, key:number) => (
                                    <div className='px-3 !py-1 !pb-0 transition-all hover:bg-gray-50 flex items-center w-full'>
                                        <Checks
                                            className="cursor-pointer mt-2"
                                            labelClassName="w-full overflow-hidden flex-1 mt-1"
                                            color="info"
                                            onChange={(e:any) => formik?.values?.chatbot_id == item?.id ? formik.setFieldValue('chatbot_id', undefined) : formik.setFieldValue('chatbot_id', item?.id)}
                                            checked={formik?.values?.chatbot_id == item?.id}
                                            label={
                                                <div className="flex items-center justify-start gap-4" onClick={() => formik?.values?.chatbot_id == item?.id ? formik.setFieldValue('chatbot_id', undefined) : formik.setFieldValue('chatbot_id', item?.id)}>
                                                    <span className="flex-1 cursor-pointer block overflow-hidden text-overflow-ellipsis text-ellipsis whitespace-nowrap">
                                                        {item?.company_name}
                                                    </span>
                                                </div>
                                            }
                                        />
                                    </div>
                                ))}
							</div>
						</DropdownMenu>
					</Dropdown>
                    {/* Date */}
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								icon='CalendarToday'
								color='dark'
								isLight
								className='position-relative'
								aria-label='Filter'>
                                Dates{/* {dayjs(formik?.values?.selectionRange?.startDate).toDate().toLocaleDateString()} - {dayjs(formik?.values?.selectionRange?.endDate).toDate().toLocaleDateString()} */}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd className='!p-0 overflow-hidden'>
							<div className='container !p-0'>
                                <DateRangePicker 
                                    className='!p-0'
                                    ranges={[formik.values.selectionRange]} 
                                    onChange={(e) => formik.setFieldValue('selectionRange', e.selection)}
                                />
							</div>
						</DropdownMenu>
					</Dropdown>
                    {/* Search */}
					<Button
						icon='Search'
						color='primary'
                        onClick={formik.handleSubmit}
                    >
						Search
					</Button>
					<SubheaderSeparator />
                    {/* Export */}
					<Button
						icon={isLoadingExport ? undefined : 'CloudDownload'}
						color='info'
						isLight
                        isDisable={isLoadingExport}
                        onClick={exportContacts}
                    >
						{isLoadingExport && <Spinner className='mr-2' isSmall />}
                        Export Contacts
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
                            {isLoading 
                                ? <div className='h-full w-full flex items-center justify-center'>
                                    <Spinner isGrow />
                                </div>
                                : !items?.length 
                                    ? <div className='h-full w-full flex flex-col gap-0 items-center justify-center p-10'>
                                        <img src="/images/not-found.webp" className='w-full max-w-[450px] -mt-10' />
                                        <h1 className='text-2xl text-gray-400 font-semibold'>No contacts found. Try clearing the filters.</h1>
                                    </div>
                                    : <>
                                        <CardBody isScrollable className='table-responsive'>
                                            <table className='table table-modern table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th>Contact</th>
                                                        <th>Email</th>
                                                        <th>Country</th>
                                                        <th>Created At</th>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {items?.map((contact:any) => (
                                                        <tr key={contact.id}>
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <div
                                                                            className='ratio ratio-1x1 me-3'
                                                                            style={{ width: 48 }}>
                                                                            {contact?.image?.url 
                                                                                ? <Avatar src={contact?.image?.url} className='w-full !rounded-xl' />
                                                                                : <div
                                                                                    className={`bg-l${
                                                                                        darkModeStatus
                                                                                            ? 'o25'
                                                                                            : '25'
                                                                                    }-${getColorNameWithIndex(
                                                                                        contact.id,
                                                                                    )} text-${getColorNameWithIndex(
                                                                                        contact.id,
                                                                                    )} rounded-2 d-flex align-items-center justify-content-center`}>
                                                                                    <span className='fw-bold'>
                                                                                        {getFirstLetter(contact.external_name || contact?.leads?.name || contact?.leads?.email || "c b")}
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex-grow-1'>
                                                                        <div className='fs-6 fw-bold'>
                                                                            {contact.external_name || contact?.leads?.name || contact?.leads?.email || "Undefined"}
                                                                        </div>
                                                                        <div className='flex gap-1 items-center'>
                                                                            <div className='text-muted flex items-center gap-1'>
                                                                                <Icon icon='SmartToy' />{' '}
                                                                                <small>{contact?.socialNetwork?.chatbot?.company_name}</small>
                                                                            </div>
                                                                            <span className='text-gray-400'>|</span>
                                                                            {(contact?.metadata?.website || contact?.socialNetwork?.chatbot?.website) && <div className='text-muted flex items-center gap-1'>
                                                                                <Icon icon='Public' />{' '}
                                                                                <small>{new URL(contact?.metadata?.website || contact?.socialNetwork?.chatbot?.website)?.origin}</small>
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {!!contact?.leads?.email && <Button
                                                                    isLink
                                                                    color='light'
                                                                    icon='Email'
                                                                    className='text-lowercase'
                                                                    tag='a'
                                                                    href={`mailto:${contact?.leads?.email}`}>
                                                                    {contact?.leads?.email}
                                                                </Button>}
                                                            </td>
                                                            <td>
                                                                {!!contact?.metadata?.country_iso_code && <div className='flex items-center gap-1.5'>
                                                                    <img src={`/images/flags/${contact?.metadata?.country_iso_code?.toLowerCase()}.png`} className='h-4 rounded-sm' />
                                                                    {getName(contact?.metadata?.country_iso_code || "")}
                                                                </div>}
                                                                {!!contact?.metadata?.ip && <div className='text-sm font-normal text-gray-400'>{contact?.metadata?.ip}</div>}
                                                            </td>
                                                            <td>
                                                                {dayjs(contact?.created_at).toDate().toLocaleString()}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    icon='Visibility'
                                                                    onClick={() => {
                                                                        setSelectedContact(contact);
                                                                        setIsOpenViewModal(true);
                                                                    }}
                                                                >
                                                                    View
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </CardBody>
                                        <PaginationButtons
                                            totalItems={metadata?.total || 0}
                                            label='customers'
                                            setCurrentPage={changePage}
                                            currentPage={currentPage}
                                            perPage={perPage}
                                            setPerPage={setPerPage}
                                        />
                                    </>
                            }
							
						</Card>
					</div>
				</div>
			</Page>
            <ViewContactModal isOpenViewModal={isOpenViewModal} setIsOpenViewModal={setIsOpenViewModal} {...selectedContact} />
		</PageWrapper>
	);
};

export default Contacts;
