import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { languageOptions, toneOptions } from "./NewChatbotModal";

interface ICardProps {
    formik: any,
    className?: string
}

const CardLanguageConfiguration: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='EmojiFlags' iconColor='success'>
                    <CardTitle>Language Configuration</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-lg-6'>
                        <FormGroup
                            id='language_iso_code'
                            label='Language'
                            isFloating>
                            <Select
                                ariaLabel='Language'
                                placeholder='Language'
                                onChange={formik.handleChange}
                                value={formik.values.language_iso_code}
                                list={languageOptions}
                            />
                        </FormGroup>
                    </div>
                    <div className='col-lg-6'>
                        <FormGroup
                            id='communication_tone'
                            label='Communication Tone'
                            isFloating>
                            <Select
                                ariaLabel='Communication Tone'
                                placeholder='Communication Tone'
                                onChange={formik.handleChange}
                                value={formik.values.communication_tone}
                                list={toneOptions}
                            />
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardLanguageConfiguration;