import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Avatar from "../../../components/Avatar";

interface ICardProps {
    formik: any,
    className?: string
}

const CardAvatar: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='Person' iconColor='info'>
                    <CardTitle>Avatar</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className="pt-0">
                <div className='row g-4 align-items-center'>
                    <div className='col-md-12'>
                        <FormGroup
                            id='assistant_name'
                            label='Avatar Name'
                            isFloating>
                            <Input
                                placeholder='Avatar Name'
                                onChange={formik.handleChange}
                                value={formik.values.assistant_name}
                                required
                            />
                        </FormGroup>
                    </div>
                    <div className='col-lg-auto'>
                        <Avatar
                            src={formik.values.social_network_image || formik?.values?.web_social_network?.image?.url || `/images/avatar-boy.png`}
                            size={75}
                            className="object-cover !h-24 !w-24"
                        />
                    </div>
                    <div className='col-lg'>
                        <div className='row g-2'>
                            <div className='col-auto flex-1'>
                                <Input
                                    type='file'
                                    autoComplete='photo'
                                    ariaLabel='Upload image file'
                                    onChange={async (e:any) => {
                                        // formik.handleChange(e);
                                        function readFileAsync() {
                                            return new Promise((resolve, reject) => {
                                                const file = e.target.files[0];
                                                if (!file) {
                                                    return;
                                                }
                                                const reader = new FileReader() as any;
                                                reader.onload = () => {
                                                    resolve(`data:${file.type};base64,${btoa(reader.result)}`);
                                                };
                                                reader.onerror = reject;
                                                reader.readAsBinaryString(file);
                                            });
                                        }
                                        const newImage = await readFileAsync();
                                        formik.setFieldValue('social_network_image', newImage);
                                    }}
                                />
                                <span className="block text-sm mt-2">This image will appear on the chat header. You can leave the suggested avatar or upload a new one.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardAvatar;