import React, { FC, useEffect, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Checks from "../../../components/bootstrap/forms/Checks";
import Api from "../../../services/Api";
import Spinner from "../../../components/bootstrap/Spinner";
import Button from "../../../components/bootstrap/Button";
import InputGroup from "../../../components/bootstrap/forms/InputGroup";
import Label from "../../../components/bootstrap/forms/Label";
import { isValidHttpsUrl } from "../../../helpers/helpers";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import TextKnowledge from "./TextKnowledge";

interface ICardProps {
    formik: any;
    className?: string;
    actualIndex?: number;
}

const CardTextKnowledge: FC<ICardProps> = ({
    formik, 
    className,
    actualIndex 
}) => {

    const [ manualURL, setManualURL ] = useState("");
    
    const handleCreateNew = () => {
        let _sources = (formik?.values?.sources || []);
        _sources.push({ type: "text", content: "", selected: true });
        formik.setFieldValue('sources', _sources);
    }

    if (!formik) return <></>;
    
    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='Description' iconColor='dark'>
                    <CardTitle>Teach Chatbro With Text</CardTitle>
                    <CardSubTitle>Add text knowledge</CardSubTitle>
                </CardLabel>
                <CardActions>
                    <Button color="success" icon="Add" onClick={handleCreateNew}>Create Text Document</Button>
                </CardActions>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-md-12 flex flex-col-reverse gap-4'>
                        {!!formik?.values?.sources?.filter((e:any) => e?.type == 'text').length  
                            ? <>
                                {formik?.values?.sources?.map((_source:any, key:number) => {
                                    if (_source?.type == "text" && _source?.action != "delete") {
                                        return <TextKnowledge index={key} source={_source} formik={formik} />;
                                    }
                                    return <></>;
                                })}
                            </> : <>
                                <p className="text-gray-400 text-base font-semibold px-2 mt-0">No text knowledge found.</p>
                            </>
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardTextKnowledge;