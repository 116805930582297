import React, { useContext, useEffect, useState } from "react";
import Api from "../services/Api";

const SessionContext = React.createContext({
	session: undefined,
	user: undefined,
	isLoggedIn: undefined,
	actions: { login: (token) => void 0, loginWaitlist: async (token) => void 0, logout: () => void 0 },
	updateUserData: () => void 0,
	updateBalance: () => void 0
});

const SessionProvider = ({ children }) => {

	const [ session, setSession ] = useState({ 
		isLoggedIn: localStorage.getItem("user") ? true : false, 
		user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : undefined
	});

	const actions = {
		login: async (token) => {
			try {
				localStorage.setItem("token", token);
				await updateUserData();
			} catch (e) {
				setSession({ isLoggedIn: false, user: undefined });
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				console.error(e);
			}
		},
		loginWaitlist: async (token) => {
			try {
				localStorage.setItem("token", token);
				await updateUserData(false);
			} catch (e) {
				setSession({ isLoggedIn: false, user: undefined });
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				console.error(e);
			}
		},
		logout: async () => {
			try {
				setSession({ isLoggedIn: false, user: undefined });
				localStorage.removeItem("token");
				localStorage.removeItem("user");
				await Api.post("/logout", {}, {}, {});
			} catch (e) {
				console.error(e);
			}
		},
	};

	const updateUserData = async (boolSetSession = true) => {
		await Api.get(`/users/me?include=roleUsers,roleUsers.role,roleUsers.chatbot,image,chatbots,chatbots.role,chatbots.image`, {}).then(async ({ data: user }) => {
			user = {...user, roleUsers: !user?.roleUsers?.length ? ["admin"] : [user?.roleUsers?.[0]?.role?.name] };
			user = {...user, role: user?.roleUsers?.[0] };
			user = {...user, chatbotsCount: user?.chatbots?.length }
			boolSetSession && setSession({ isLoggedIn: true, user: user });

			try {
				await Api.get(`/subscriptions?include=items.product,items.price`, {}, {}).then((response) => {
					user = {...user, subscription: response.data};
					boolSetSession && setSession({ isLoggedIn: true, user: user });
				}).catch((err) => {});
			} catch (err) {
				boolSetSession && setSession({ isLoggedIn: true, user: user });
			}

			try {
				await Api.get(`/subscriptions/balance`, {}, {}).then((response) => {
					user = {...user, balance: response.data};
					boolSetSession && setSession({ isLoggedIn: true, user: user });
				});
			} catch (err) {
				boolSetSession && setSession({ isLoggedIn: true, user: user });
			};
			
			try {
				window.Intercom('boot', {
					app_id: 'vve4bzuc',
					name: user?.name,
					email: user?.email,
					user_id: user?.id,
					created_at: Math.ceil(new Date(user?.created_at).getTime() / 1000),
					chatbots_count: user?.chatbots?.length
				});
			} catch (err) {}
		}).catch((err) => {
			boolSetSession && setSession({ isLoggedIn: false, user: undefined })
			localStorage.removeItem("token");
			localStorage.removeItem("user");
		})
	};

	const updateBalance = async () => {
		if (session?.user) {
			try {
			   Api.get(`/subscriptions/balance`, {}, {}).then((response) => {
				   const balance = response.data;
				   let newUser = session?.user;
				   newUser.balance = balance;
				   setSession({ isLoggedIn: true, user: newUser });
			   });
		   } catch (err) {};
		}
	}

	useEffect(() => {
		const token = localStorage.getItem("token");
		const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
		if (token && user) {
			setSession({ isLoggedIn: true, user: JSON.parse(localStorage.getItem("user")) });
			updateUserData(token);
		}
	}, []);

	useEffect(() => {
		if (session?.user) {
			localStorage.setItem("user", JSON.stringify(session?.user));
		}
	}, [session]);

	return (
		<SessionContext.Provider value={{
			session, 
			actions, 
			updateUserData,
			updateBalance,
			isLoggedIn: session?.isLoggedIn,
			user: session?.user
		}}>
			{children}
		</SessionContext.Provider>
	);
};

export { SessionContext, SessionProvider };