import React, { FC, useEffect, useState } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, leadFieldOptions, toneOptions } from "./NewChatbotModal";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import showNotification from "../../../components/extras/showNotification";
import SortableList, { SortableItem } from 'react-easy-sort'
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";


interface ICardProps {
    formik: any;
    className?: string;
    onSortEnd?: any;
    deleteButton?: any;
    rand?: any;
}

const CardQuickButtons: FC<ICardProps> = ({
    formik, 
    className 
}) => {
    
    const [ buttonLabel, setButtonLabel ] = useState("");
    const [ buttonMessage, setButtonMessage ] = useState("");
    const [ myDummyStateValue, setMyDummyStateValue ] = useState(0);
    const [ showForm, setShowForm ] = useState(false);
    
    const quickButtonSuggestions = [
        {    
            label: "Pricing", 
            message: "What's the price?"
        },
        {    
            label: "Features", 
            message: "Tell me about the features."
        },
        {    
            label: "Contact", 
            message: `How can I contact ${formik?.values?.company_name}?`
        },
        {    
            label: "Returns", 
            message: "What is your return policy?"
        },
        {    
            label: "Account", 
            message: "How do I create an account?"
        },
        {    
            label: "Payment", 
            message: "What payment methods do you accept?"
        },
        {    
            label: "Privacy", 
            message: "Tell me about your privacy policy."
        },
        {    
            label: "Warranty", 
            message: "What is your product warranty policy?"
        },
        {    
            label: "About Us", 
            message: `Tell me about ${formik?.values?.company_name}.`
        },
        {    
            label: "Reviews", 
            message: "Can I see customer reviews?"
        },
        {    
            label: "Discounts", 
            message: "Are there any ongoing discounts?"
        },
        {    
            label: "Blog", 
            message: "What topics do you cover in your blog?"
        },
        {    
            label: "Partnerships", 
            message: "Do you offer partnership opportunities?"
        },
        {    
            label: "Affiliates", 
            message: "Do you have an affiliate program?"
        },
        {    
            label: "Languages", 
            message: "What languages do you support?"
        },
    ];

    const handleOnClickSuggestion = (label: any, message: any) => {
        formik.setFieldValue('predefined_buttons', [...(formik?.values?.predefined_buttons || []), { label: label, message: message }]);
    }

    const addButton = () => {
        if (!buttonLabel.trim() || !buttonMessage.trim()) {
            showNotification('ERROR', 'You must fill all required fields.', 'danger');
        } else {
            formik.setFieldValue('predefined_buttons', [...(formik?.values?.predefined_buttons || []), { label: buttonLabel, message: buttonMessage }]);
            setButtonLabel("");
            setButtonMessage("");
        }
    }

    const onSortEnd = (oldIndex:number, newIndex:number) => {
        let newArray = formik?.values?.predefined_buttons
        const _itemToMove = newArray.splice(oldIndex, 1);
        newArray.splice(newIndex, 0, ..._itemToMove);
        formik.setFieldValue('predefined_buttons', newArray);
    }

    const deleteButton = (key:number) => {
        let newValues = formik?.values?.predefined_buttons;
        newValues?.splice(key, 1);
        formik.setFieldValue('predefined_buttons', newValues);
    }

    useEffect(() => {
        setMyDummyStateValue(formik?.values?.predefined_buttons?.length);
    }, [formik?.values?.predefined_buttons]);
    
    if (!formik) return <></>;
    
    return (
        <>
            <Alert
                isLight
                color='info'
                borderWidth={0}
                className='shadow-3d-info'
                isDismissible
            >
                <div className="flex gap-3 items-start">
                    <div className="shrink-0 pt-0.5">
                        <Icon icon="Info" color="info" size="3x" />
                    </div>
                    <span className="flex-1">
                        These are predefined buttons that serve as shortcuts to commonly asked questions. By clicking on a button with a specific label, you can quickly initiate a conversation about a particular topic. For example, clicking the 'Pricing' button will automatically prompt the question 'What's the price?' to Chatbros. These buttons were created to enhance user convenience, allowing you to obtain specific information without having to type out the question manually.
                    </span>
                </div>
            </Alert>
            <Card>
                <CardHeader>
                    <CardLabel icon='SmartButton' iconColor='dark'>
                        <CardTitle>My Buttons</CardTitle>
                        <CardSubTitle>Add Quick Buttons to answer frequently asked questions with one click.</CardSubTitle>
                    </CardLabel>
                    <CardActions className="flex gap-0 items-center">
                        <Dropdown>
                            <DropdownToggle>
                                <Button className="!h-auto" color='info' isLight>Suggestions</Button>
                            </DropdownToggle>
                            <DropdownMenu isAlignmentEnd={true} className="max-h-[250px] overflow-y-auto" size="lg">
                                {quickButtonSuggestions.filter((item:any) => formik?.values?.predefined_buttons?.filter((e:any) => e?.label?.toLowerCase() == item?.label?.toLowerCase())?.length == 0).map((item:any) => (
                                    <DropdownItem onClick={() => handleOnClickSuggestion(item?.label, item?.message)} className="!py-2 !h-auto w-full overflow-hidden whitespace-nowrap">
                                        <div className="flex items-center gap-2 text-sm w-full">
                                            <div className="flex flex-1 flex-col gap-1 -ml-2 pl-2 w-full overflow-hidden w-full">
                                                <span>{item.label}</span>
                                                <span className="w-full font-semibold text-ellipsis overflow-ellipsis text-overflow-ellipsis">{item.message}</span>
                                            </div>
                                            <Icon icon="Add" className="shrink-0 !m-0" />
                                        </div>
                                    </DropdownItem> 
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button icon="Add" color="success" onClick={() => setShowForm(!showForm)}>
                            Add Button
                        </Button>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className={`transition-all flex items-center gap-2 mb-4 -mt-4 ${!showForm && `opacity-0 h-0 !pointer-events-none`}`}>
                        <div className="flex-1">
                            <FormGroup
                                id='buttonLabel'
                                label='Button Label'
                                // formText={`Button text. For example: \"Pricing\"`}
                                isFloating>
                                <Input
                                    placeholder='Button Label'
                                    onChange={(e:any) => { setButtonLabel(e.target.value) }}
                                    value={buttonLabel}
                                />
                            </FormGroup>
                        </div>
                        <div className="flex-1">
                            <FormGroup
                                id='buttonMessage'
                                label='Button Message'
                                // formText={`The actual message. For example: \"What's the price?\"`}
                                isFloating>
                                <Input
                                    placeholder='Button Message'
                                    onChange={(e:any) => { setButtonMessage(e.target.value) }}
                                    value={buttonMessage}
                                />
                            </FormGroup>
                        </div>
                        <div className='shrink-0'>
                            <Button 
                                icon="Add" 
                                size='lg' 
                                color="info"
                                onClick={addButton}
                                className="!whitespace-nowrap !rounded-2xl"
                            />
                        </div>
                    </div>
                    {(formik?.values?.predefined_buttons || []).length == 0 && 
                        <p>You don't have any Quick Buttons yet. Add them using the form above.</p>
                    }
                    <SortableList onSortEnd={onSortEnd} className="flex gap-3 flex-wrap">
                        {(formik?.values?.predefined_buttons || [])?.map((item:any, key:number) => (
                            <SortableItem key={key}>
                                <div>
                                    <Badge isLight color="dark" className="cursor-grab active:cursor-grabbing !text-left !text-sm !rounded-2xl flex items-center justify-between !p-2.5 !px-5 !pl-3 !gap-6">
                                        <CardLabel icon="DragIndicator" className="gap-0" iconColor='gray-400'>
                                            <CardTitle>{item?.label}</CardTitle>
                                            <CardSubTitle className="font-normal">{item?.message}</CardSubTitle>
                                        </CardLabel>
                                        <CardActions>
                                            <Button isLink onClick={() => deleteButton(key)} icon="Delete" size="sm" color="danger"/>
                                        </CardActions>
                                    </Badge>
                                </div>
                            </SortableItem>
                        ))}
                    </SortableList>
                </CardBody>
            </Card>
        </>
    );
}

export default CardQuickButtons;