import React, { useContext, useEffect, useState } from "react";
import Api from "../services/Api";
import PricingModal from "../components/PricingModal";

const PricingModalContext = React.createContext({
	isOpenPricingModal: false,
	setIsOpenPricingModal: (value) => void 0
});

const PricingModalProvider = ({ children }) => {

	const [ isOpenPricingModal, setIsOpenPricingModal ] = useState(false);

	return (
		<PricingModalContext.Provider value={{
			isOpenPricingModal,
			setIsOpenPricingModal
		}}>
			<PricingModal />
			{children}
		</PricingModalContext.Provider>
	);
};

export { PricingModalContext, PricingModalProvider };