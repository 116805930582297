import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../components/bootstrap/Card";

const SupportCard = () => {

    const { user } = useContext<any>(SessionContext);
    
    return (        
        <Card stretch>
            <CardBody className="d-flex align-items-center !gap-4 flex-col-reverse lg:flex-row">
                <div className="flex-grow-1">
                    <div className="fs-5 fw-bold !text-center lg:!text-left">Need Help or a Friendly Chat?</div>
                    <div className="text-muted mt-2 !text-center lg:!text-left">Hey {user?.name || `there`}! With Chatbros, you're never alone. Reach out via email, or schedule a call for the assistance you deserve.</div>
                    <div className="row mt-2 g-3 items-center justify-center lg:justify-start">
                        <div className="col-auto">
                            <Button tag="a" icon="Duo" color="info" isLight href="https://calendly.com/marcosp-chatbros/30min" target="_blank">Schedule a 30-min call</Button>
                        </div>
                        {/* <div className="col-auto">
                            <Button tag="a" icon="Email" color="info" isLight href="mailto:hello@chatbros.ai">Send us an email</Button>
                        </div> */}
                        <div className="col-auto">
                            <Button icon="Chat" color="info" isLight onClick={() => (window as any)?.Chatbros('open')}>Chat with us</Button>
                        </div>
                    </div>
                </div>
                <div className="flex-shrink-0">
                    <img className="avatar rounded-circle shadow-sm bg-l25-danger rounded-circle" src="/images/marcos.png" alt="Avatar" width="110" height="110" />
                </div>
            </CardBody>
        </Card>
    )
}

export default SupportCard;