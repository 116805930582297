import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';
import { SessionContext } from '../../contexts/SessionContext';
import Button from '../../components/bootstrap/Button';
import { API_HOST } from '../../services/Api';
import Progress from '../../components/bootstrap/Progress';
import SubscriptionNavbar from '../../components/SubscriptionNavbar';
import Avatar from '../../components/Avatar';

const User = () => {

	const { user, actions } = useContext<any>(SessionContext);
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<SubscriptionNavbar />
			<div
				className={classNames('user', 'w-auto !overflow-hidden cursor-default !rounded-xl', { open: true })}
				role='presentation'
				// onClick={() => setCollapseStatus(!collapseStatus)}
			>
				<div className='user-avatar shrink-0'>
					<Avatar
						src={user?.image?.url}
						size={48}
						color='primary'
					/>
				</div>
				<div className='user-info flex-1 !overflow-hidden'>
					<div className='user-name'>
						{user?.name}
					</div>
					<div className='user-sub-title text-ellipsis ellipsis text-overflow-ellipsis overflow-hidden max-w-full'>
						{user?.email}
					</div>
				</div>
			</div>

			<Collapse isOpen={true} className='user-menu'>
				{/* <nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`/`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav> */}
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={actions.logout}
						>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
