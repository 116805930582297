import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authPages, dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu, previewPages } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: authPages.login.path, element: null },
	{ path: authPages.login.path + `ok`, element: null },
	{ path: previewPages.preview.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: `*`, element: <DashboardHeader /> },
];

export default headers;
