import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Checks from "../../../components/bootstrap/forms/Checks";
import Avatar from "../../../components/Avatar";

interface ICardProps {
    formik: any,
    className?: string
}

const CardCompanyLogo: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='Image' iconColor='info'>
                    <CardTitle>Company Logo</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className="pt-0">
                <div className='col-12'>
                    <div className='row g-4 align-items-center'>
                        <div className='col-lg-auto'>
                            <Avatar
                                src={formik?.values?.new_image || formik?.values?.image?.url || `/images/placeholder.png`}
                                size={75}
                                className="object-contain !h-24 !w-24 !bg-gray-200 !p-2"
                                rounded={3}
                            />
                        </div>
                        <div className='col-lg'>
                            <div className='row g-2'>
                                <div className='col-auto flex-1'>
                                    <Input
                                        type='file'
                                        autoComplete='photo'
                                        ariaLabel='Upload image file'
                                        onChange={async (e:any) => {
                                            // formik.handleChange(e);
                                            function readFileAsync() {
                                                return new Promise((resolve, reject) => {
                                                    const file = e.target.files[0];
                                                    if (!file) {
                                                        return;
                                                    }
                                                    const reader = new FileReader() as any;
                                                    reader.onload = () => {
                                                        resolve(`data:${file.type};base64,${btoa(reader.result)}`);
                                                    };
                                                    reader.onerror = reject;
                                                    reader.readAsBinaryString(file);
                                                });
                                            }
                                            const newImage = await readFileAsync();
                                            formik.setFieldValue('new_image', newImage);
                                        }}
                                    />
                                    <span className="block text-sm mt-2">This image will appear on the chatbot initial screen header.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardCompanyLogo;