import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { getFullDomain, validateEmail } from '../../../helpers/helpers';
import Api, { API_HOST } from '../../../services/Api';
import showNotification from '../../../components/extras/showNotification';
import { SessionContext } from '../../../contexts/SessionContext';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Enter your email to continue.</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {

	const { darkModeStatus } = useDarkMode();
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ isLoggingIn, setIsLoggingIn ] = useState<boolean>(false);
	const { actions } = useContext(SessionContext);
	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			password: ""
		},
		validate: (values) => {
			const errors: { email?: string; } = {};
			if (!validateEmail(values.email)) {
				errors.email = 'You need to enter a valid email';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				await Api.post("/login", {}, { 
					email: values.email.trim(), 
					password: values.password?.trim() ? values.password?.trim() : undefined,
					frontend_url: getFullDomain() 
				}, {}).then(({ data }) => {
					if (values.password?.trim() && data?.token) {
						setIsLoggingIn(true);
						(actions.login(data?.token) as any).then(() => {
							setIsLoggingIn(false);
						}).catch(() => {
							setIsLoggingIn(false);
						});
					}
				});
			} catch {}
			formik.resetForm();
			if (!values.password?.trim()) {
				showNotification('Login Email Sent!', 'An email has been sent to the provided email with a login link.', 'success');
			}
			setIsLoading(false);
		},
	});

	useEffect(() => {
		if (searchParams.get("token")) {
			setIsLoggingIn(true);
			(actions.login(searchParams?.get("token")) as any).then(() => {
				setIsLoggingIn(false);
			}).catch(() => {
				setIsLoggingIn(false);
			});
		}
	}, []);

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className="bg-dark"
		>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center' style={{ backgroundImage: 'url(/images/footer-bg-shapes.svg', backgroundSize: '200%', backgroundPosition: '100px 50%', backgroundRepeat: 'no-repeat'}}>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							{isLoggingIn 
								? <CardBody className='flex w-full justify-center items-center py-56'>
									<Spinner isGrow />
								</CardBody>
								: <CardBody>
									<div className='text-center my-5'>
										<Link
											to='/'
											className={classNames(
												'text-decoration-none  fw-bold display-2',
												{
													'text-dark': !darkModeStatus,
													'text-light': darkModeStatus,
												},
											)}
											aria-label='Facit'>
											<Logo className={`w-full max-w-[90px] mx-auto`} />
										</Link>
									</div>

									<LoginHeader isNewUser={false} />

									<div className='row g-2 mb-4'>

										<div className='col-12'>
											<FormGroup
												id='email'
												isFloating
												label='Your email'
											>
												<Input
													autoComplete='username'
													value={formik.values.email}
													isTouched={formik.touched.email}
													invalidFeedback={formik.errors.email}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													// onBlur={formik.handleBlur}
													onFocus={() => { formik.setErrors({}); }}
													onKeyDown={(e) => e.key == 'Enter' && formik.submitForm()}
													disabled={isLoading}
												/>
											</FormGroup>
										</div>
										{formik?.values?.email == "appsumo@test.com" && <div className='col-12'>
											<FormGroup
												id='password'
												isFloating
												label='Your password'
											>
												<Input
													value={formik.values.password}
													isTouched={formik.touched.password}
													invalidFeedback={formik.errors.password}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onFocus={() => { formik.setErrors({}); }}
													onKeyDown={(e) => e.key == 'Enter' && formik.submitForm()}
													disabled={isLoading}
													type="password"
												/>
											</FormGroup>
										</div>}
										<div className='col-12'>
											<Button
												color='warning'
												className='w-100 py-3 bg-gradient-primary text-white border-0'
												isDisable={!formik.values.email || isLoading}
												onClick={() => formik.submitForm()}
											>
												{isLoading && (
													<Spinner isSmall inButton isGrow />
												)}
												Login
											</Button>
										</div>

										<div className='col-12 mt-4 mb-2 text-center text-muted'>
											OR
										</div>

										<div className='col-12 mt-3'>
											<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='CustomFacebook'
												onClick={() => window.location.href = `${API_HOST}/login/facebook/redirect?frontend_url=${getFullDomain()}`}
											>
												Sign in with Facebook
											</Button>
										</div>
										<div className='col-12'>
											<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='CustomGoogle'
												onClick={() => window.location.href = `${API_HOST}/login/google/redirect?frontend_url=${getFullDomain()}`}
											>
												Continue with Google
											</Button>
										</div>
									</div>
								</CardBody>
							}
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': true,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('text-decoration-none', {
									'link-light': true,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
