import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../contexts/SessionContext";
import Button from "./bootstrap/Button";
import Api, { API_HOST } from "../services/Api";
import Progress from "./bootstrap/Progress";
import dayjs from "dayjs";
import { PricingModalContext } from "../contexts/PricingModalContext";
import { formatNumber, hasActiveSubscription } from "../helpers/helpers";
import { plans } from "./PricingModal";
import Icon from "./icon/Icon";

const SubscriptionNavbar = () => {

	const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);

    return (
        <>
            {/* {!hasActiveSubscription(user) && <div className='px-2.5 mt-2'>
                <Button
                    type='button'
                    color='primary'
                    icon='Bolt'
                    className='w-full !py-3 subscription-widget-nav'
                    onClick={() => setIsOpenPricingModal(true)}
                >
                    Subscribe Now
                </Button>
            </div>} */}
            {<div className='flex flex-col gap-0 px-2 mt-2'>
                <Button
                    type='button'
                    color='light'
                    className='flex !flex-col !py-3 !w-full !rounded-xl subscription-widget-nav !rounded-b-none !bg-white'
                    onClick={() => setIsOpenPricingModal(true)}
                >
                    <div className="!flex flex-col !items-center justify-center gap-3.5 w-full">
                        {/* <div className='shrink-0 flex gap-2 items-center justify-center w-full'>
                            <Icon
                                icon={plans.filter((e) => e.id.month == user?.subscription?.items?.[0]?.price.id || e.id.year == user?.subscription?.items?.[0]?.price.id)?.[0].icon}
                                color='primary'
                                size='2x'
                            />
                            <span className="!text-lg capitalize">{user?.subscription?.items?.[0]?.product?.name}</span>
                        </div> */}
                        <div className='w-full flex-1 flex flex-col items-start gap-1.5 mt-1'>
                            <div className="w-full text-right text-xs flex items-center justify-between">
                                <span>Chatbros</span>
                                <span>{user?.balance?.chatbots_spent} / {formatNumber(user?.balance?.chatbots_allowed, 0, true)}</span>
                            </div>
                            <Progress 
                                value={user?.balance?.chatbots_allowed === null ? 1000000000 : user?.balance?.chatbots_spent}
                                max={user?.balance?.chatbots_allowed === null ? 1000000000 : user?.balance?.chatbots_allowed}
                                height={7}
                                color='primary'
                                className="w-full"
                                isAnimated={user?.balance?.chatbots_allowed === null}
                            />
                        </div>
                        <div className='w-full flex-1 flex flex-col items-start gap-1.5 mt-1 mb-2'>
                            <div className="w-full text-right text-xs flex items-center justify-between">
                                <span>Conversations</span>
                                <span>{formatNumber(user?.balance?.conversations_spent)} / {formatNumber(user?.balance?.conversations_allowed, 0, true)}</span>
                            </div>
                            <Progress 
                                value={user?.balance?.conversations_allowed === null ? 1000000000 : user?.balance?.conversations_spent}
                                max={user?.balance?.conversations_allowed === null ? 1000000000 : user?.balance?.conversations_allowed}
                                height={7}
                                color='primary'
                                className="w-full"
                                isAnimated={user?.balance?.conversations_allowed === null}
                            />
                            {/* <div className="w-full text-left font-normal text-xs pt-2.5 text-center">
                                Renews {dayjs(user?.balance?.conversations_renews_at).fromNow()}
                            </div> */}
                        </div>
                    </div>
                </Button>
                <Button
                    type='button'
                    color='primary'
                    icon='Bolt'
                    className='w-full !py-3 subscription-widget-nav !rounded-t-none'
                    onClick={() => setIsOpenPricingModal(true)}
                >
                    Upgrade Plan
                </Button>
            </div>}
        </>
    )
}

export default SubscriptionNavbar;