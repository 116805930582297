import React, { FC, useContext } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Checks from "../../../components/bootstrap/forms/Checks";
import Button from "../../../components/bootstrap/Button";
import { SessionContext } from "../../../contexts/SessionContext";
import { PricingModalContext } from "../../../contexts/PricingModalContext";
import { hasActiveSubscription } from "../../../helpers/helpers";

interface ICardProps {
    formik: any,
    className?: string
}

const CardActivateChatbot: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);

    if (!formik) return <></>;

    return (
        <Card className={`overflow-hidden ${className}`}>
            <CardHeader>
                <CardLabel icon={formik.values.active ? 'RadioButtonChecked' : 'PowerOff'} iconColor={formik.values.active ? 'success' : 'dark'}>
                    <CardTitle>Chatbro is {formik.values.active ? 'active' : 'inactive'}</CardTitle>
                </CardLabel>
                <CardActions>
                    <CardSubTitle>
                        {!hasActiveSubscription(user) 
                            ? <>
                                <Button
                                    color={'success'}
                                    icon={'PowerSettingsNew'}
                                    onClick={() => setIsOpenPricingModal(true)}
                                >
                                    Activate Chatbro
                                </Button>
                            </>
                            : <>
                                <Button
                                    color={formik.values.active ? 'danger' : 'success'}
                                    icon={formik.values.active ? 'PowerOff' : 'PowerSettingsNew'}
                                    onClick={() => formik.setFieldValue('active', !formik?.values?.active)}
                                >
                                    {formik.values.active ? 'Deactivate' : 'Activate'} Chatbro
                                </Button>
                            </>
                        }
                    </CardSubTitle>
                </CardActions>
            </CardHeader>
            {/* <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-md-12'>
                        <FormGroup
                            id='active'
                            label='Activate Chatbro'
                            isFloating>
                                <div className="form-control flex flex-col gap-3 !h-full max-h-[200px] overflow-y-auto">
                                    <Checks
                                        type='switch'
                                        id='active'
                                        name='active'
                                        className="cursor-pointer"
                                        color="info"
                                        onChange={(e:any) => {
                                            formik.setFieldValue('active', e.target.checked);
                                        }}
                                        checked={formik?.values?.active}
                                        label={<span className="cursor-pointer block mt-[2px] text-sm" onClick={() => formik.setFieldValue('active', !formik?.values?.active)}>
                                            {formik?.values?.active
                                                ? `Chatbro is active`
                                                : `Chatbro is inactive`
                                            }
                                        </span>}
                                    />
                                </div>
                        </FormGroup>
                    </div>
                </div>
            </CardBody> */}
        </Card>
    );
}

export default CardActivateChatbot;