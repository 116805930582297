import React, { useEffect, useRef, useState } from "react";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../../components/bootstrap/Card";
import Avatar from "../../../components/Avatar";
import { getColorNameWithIndex } from "../../../common/data/enumColors";
import Icon from "../../../components/icon/Icon";
import useDarkMode from "../../../hooks/useDarkMode";
import { getFirstLetter } from "../../../helpers/helpers";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import dayjs from "dayjs";
import Spinner from "../../../components/bootstrap/Spinner";
import ChatbotPreview from "../../chatbot_preview/ChatbotPreview";

const PreviewChatbro = (props: any) => {

    const {
        formik
    } = props;

    const [ rand, setRand ] = useState(0);
    const [ isLoadingIframe, setIsLoadingIframe ] = useState(true);
    const iframeRef = useRef<any>();

    useEffect(() => {
        window.addEventListener('message', (e) => {
            iframeRef.current?.contentWindow?.postMessage(e.data);
        });
    }, []);

    // useEffect(() => {
    //     if (isOpenPreviewModal) {
    //         setRand(Math.random());
    //     }
    // }, [isOpenPreviewModal]);

    if (!formik) return <></>;

    return (
        <Card className='max-w-[475px] mx-auto h-[600px] -mt-6 -mb-8'>
            <CardBody className="h-full flex-1 overflow-y-auto flex w-full items-center justify-center">
                {isLoadingIframe && <Spinner isGrow />}
                <iframe 
                    src={`${window.location.href.includes("localhost") ? `http://localhost:3001` : `https://widget.chatbros.ai`}/chat?id=${formik?.values?.external_id}&url=${window.location.href?.split("#")?.[0]}&open=true`} 
                    className={`w-full h-full rounded-3xl overflow-hidden shadow-[0_1.6rem_3rem_rgba(0,0,0,0.1)] ${isLoadingIframe && `!hidden`}`}
                    key={rand}
                    onLoad={() => setIsLoadingIframe(false)}
                />
                {/* <iframe 
                    ref={iframeRef}
                    key={rand}
                    onLoad={() => setIsLoadingIframe(false)}
                    src={`/preview.html?external_id=${formik?.values?.external_id}&rand=${rand}&domain=${process.env.REACT_APP_WIDGET_URL}`}
                    className={`w-full h-full rounded-3xl overflow-hidden shadow-[0_1.6rem_3rem_rgba(0,0,0,0.1)] ${isLoadingIframe && `!hidden`}`}
                /> */}
            </CardBody>
        </Card>
    )
}

export default PreviewChatbro;