import { FC, useEffect, useRef } from "react";


const ChatbotPreview: FC<any> = ({ external_id }) => {

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const url = new URL(window.location.href);
            const _external_id = external_id || url.searchParams.get("external_id");
    
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.id='chatbro_script'
            s1.async=true;
            s1.src=`${process.env.REACT_APP_WIDGET_URL}/include${process.env.REACT_APP_WIDGET_URL?.includes('localhost') ? `_localhost` : ``}.js?id=${_external_id}`;
            s1.setAttribute('crossorigin','*');
            s0?.parentNode?.insertBefore(s1,s0);
        }
    }, []);

    return (
        <></>
    );
}

export default ChatbotPreview;