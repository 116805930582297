import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Checks from "../../../components/bootstrap/forms/Checks";

interface ICardProps {
    formik: any,
    className?: string
}

const CardWelcomeMessage: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='WavingHand' iconColor='primary'>
                    <CardTitle>Welcome Message</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='row g-4'>
                    <div className='col-md-12'>
                        <FormGroup
                            id='show_welcome_message'
                            label='Show Welcome Message'
                            isFloating>
                                <div className="form-control flex flex-col gap-3 !h-full max-h-[200px] overflow-y-auto">
                                    <Checks
                                        type='switch'
                                        id='show_welcome_message'
                                        name='show_welcome_message'
                                        className="cursor-pointer"
                                        color="info"
                                        onChange={(e:any) => {
                                            formik.setFieldValue('show_welcome_message', e.target.checked);
                                        }}
                                        checked={formik?.values?.show_welcome_message}
                                        label={<span className="cursor-pointer block mt-[2px] text-sm" onClick={() => formik.setFieldValue('show_welcome_message', !formik?.values?.show_welcome_message)}>Show welcome message on page load</span>}
                                    />
                                </div>
                        </FormGroup>
                    </div>
                    <div className='col-md-12'>
                        <FormGroup
                            id='welcome_message'
                            label='Custom Welcome Message (leave blank for AI generated message)'
                            isFloating>
                                <Input
                                    placeholder='Custom Welcome Message'
                                    onChange={formik.handleChange}
                                    value={formik.values.welcome_message}
                                />
                        </FormGroup>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CardWelcomeMessage;