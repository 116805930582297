import { FC, useContext, useEffect, useState } from "react";
import Alert, { AlertHeading } from "../../../components/bootstrap/Alert";
import Spinner from "../../../components/bootstrap/Spinner";
import { SessionContext } from "../../../contexts/SessionContext";
import { formatNumber, hasActiveSubscription } from "../../../helpers/helpers";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import { PricingModalContext } from "../../../contexts/PricingModalContext";
import Api from "../../../services/Api";


const LearningAlert:FC<{ formik: any }> = ({ 
    formik
}) => {

    const { user } = useContext<any>(SessionContext);
    const { setIsOpenPricingModal } = useContext(PricingModalContext);

    const [ sources, setSources ] = useState<any>([]);
    const [ totalSources, setTotalSources ] = useState<number>(0);
    const [ maxSources, setMaxSources ] = useState<number>(0);
    const [ usedSources, setUsedSources ] = useState<number>(0);
    const [ selectedSources, setSelectedSources ] = useState<number>(0);
    const [ sourcesToProcess, setSourcesToProcess ] = useState<number>(0);
    const [ processedSources, setProcessedSources ] = useState<number>(0);
    const [ errorSources, setErrorSources ] = useState<number>(0);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingRetryAll, setIsLoadingRetryAll ] = useState(false);

    const handleRetryAll = () => {
        setIsLoadingRetryAll(true);
        let _sources = sources;
        _sources = _sources?.map((item:any) => {
            if (item?.status == 'error' && item?.action != 'delete') {
                item.action = 'reprocess';
            }
            return item;
        })
        formik?.setFieldValue('dontLoad', true);
        formik?.setFieldValue('sources', _sources);
        formik?.submitForm().then(() => {
            setTimeout(() => {
                getSources();
                setTimeout(() => {
                    setIsLoadingRetryAll(false);
                }, 2000)
            }, 5000);
        }).finally(() => {
            formik?.setFieldValue('dontLoad', false);
        });
    }

    const getSources = async () => {
        if (
            totalSources && 
            selectedSources
        ) {
            await Api.get(`/chatbots/${formik?.values?.id}?include=sources`).then(({ data: chatbot }) => {
                const _maxSources = user?.balance?.chatbot_sources_allowed;
                const _selectedSources = chatbot?.sources?.filter((e:any) => !!e?.selected && !!e?.status)?.length;
                setSources(chatbot?.sources);
                setTotalSources(chatbot?.sources?.length);
                setMaxSources(_maxSources);
                setUsedSources(user?.balance?.chatbot_sources_spent);
                setSelectedSources(_selectedSources);
                setSourcesToProcess(Math.min(_maxSources, _selectedSources));
                setProcessedSources(chatbot?.sources?.filter((e:any) => e?.status == "processed" && e?.selected == true)?.length);
                setErrorSources(chatbot?.sources?.filter((e:any) => e?.status == "error")?.length);

                // Dynamically update status and status message from formik values
                let _formikSources = formik?.values?.sources;
                _formikSources = _formikSources?.map((item:any) => {
                    const _newItem = chatbot?.sources?.filter((e:any) => e?.id == item?.id)?.[0] || item;
                    return {
                        ...item,
                        status: _newItem?.status || item?.status,
                        status_message: _newItem?.status_message || item?.status_message
                    }
                });
                formik?.setFieldValue('sources', _formikSources);
            }).catch((err) => {});
        }
    }

    useEffect(() => {
        const _maxSources = user?.balance?.chatbot_sources_allowed || 0;
        const _selectedSources = formik?.values?.sources?.filter((e:any) => !!e?.selected && !!e?.status)?.length || 0;
        setSources(formik?.values?.sources);
        setTotalSources(formik?.values?.sources?.length);
        setMaxSources(_maxSources);
        setUsedSources(user?.balance?.chatbot_sources_spent);
        setSelectedSources(_selectedSources);
        setSourcesToProcess(_selectedSources);
        setProcessedSources(formik?.values?.sources?.filter((e:any) => e?.status == "processed")?.length);
        setErrorSources(formik?.values?.sources?.filter((e:any) => e?.status == "error")?.length);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getSources();
    }, []);
    
    useEffect(() => {
        const interval = setInterval(() => {
            getSources();
        }, 20000);
        return () => clearInterval(interval);
    }, [totalSources, selectedSources, processedSources, sourcesToProcess, user?.balance?.chatbot_sources_allowed, formik?.values?.sources]);

    if (isLoading) return <></>;

    // No sources
    if (!selectedSources) {
        return (
            <div className='relative'>
                <Alert
                    isLight
                    color='info'
                    borderWidth={0}
                    className='shadow-3d-info !flex-nowrap'
                >
                    <div className="flex items-center gap-4 w-full">
                        <div className="shrink-0">
                            <Icon 
                                color="info"
                                size="4x"
                                icon="School"
                            />
                        </div>
                        <div className="flex-1">
                            <b className="block">Knowledge status</b>
                            <span>You haven't added/selected any data sources (knowledge) to your Chatbro yet. Search a website URL or add text knowledge below.</span>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }

    // // No subscription
    // if (!hasActiveSubscription(user) && selectedSources > 0 && maxSources == 0) {
    //     return (
    //         <div className='relative'>
    //             <Alert
    //                 isLight
    //                 color='primary'
    //                 borderWidth={0}
    //                 className='shadow-3d-primary !flex-nowrap'
    //             >
    //                 <div className="flex items-center gap-4 w-full">
    //                     <div className="shrink-0">
    //                         <Icon 
    //                             color="primary"
    //                             size="4x"
    //                             icon="Bolt"
    //                         />
    //                     </div>
    //                     <div className="flex-1 -ml-4">
    //                         <b className="block">Knowledge status</b>
    //                         <span>You need a valid subscription for your data sources to be processed.</span>
    //                     </div>
    //                     <div className="shrink-0 flex flex-col items-center justify-center">
    //                         <Button
    //                             type='button'
    //                             color='primary'
    //                             icon='Bolt'
    //                             className='!py-3'
    //                             onClick={() => setIsOpenPricingModal(true)}
    //                         >
    //                             Subscribe Now
    //                         </Button>
    //                     </div>
    //                 </div>
    //             </Alert>
    //         </div>
    //     );
    // }

    // Everything OK
    if (processedSources == sourcesToProcess && errorSources == 0) {
        return (
            <div className='relative'>
                <Alert
                    isLight
                    color='success'
                    borderWidth={0}
                    className='shadow-3d-success !flex-nowrap'
                >
                    <div className="flex items-center gap-4 w-full">
                        <div className="shrink-0">
                            <Icon 
                                color="success"
                                size="4x"
                                icon="CheckCircle"
                            />
                        </div>
                        <div className="flex-1 w-full">
                            <b className="block">Knowledge status</b>
                            <span>All your data sources have been successfully processed. You are good to go!</span>
                        </div>
                        <div className="shrink-0 flex flex-col items-center justify-center pr-2">
                            <h3 className="font-bold text-2xl">{formatNumber(processedSources)} / {formatNumber(sourcesToProcess)}</h3>
                            <small className="text-center">Processed</small>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }

    // Need to upgrade your subscription
    if (sourcesToProcess > maxSources && processedSources == maxSources) {
        return (
            <div className='relative'>
                <Alert
                    isLight
                    color='dark'
                    borderWidth={0}
                    className='shadow-3d-dark !flex-nowrap'
                >
                    <div className="flex items-center gap-4 w-full">
                        <div className="shrink-0">
                            <Icon 
                                color="dark"
                                size="4x"
                                icon="Warning"
                            />
                        </div>
                        <div className="flex-1 w-full">
                            <b className="block">Knowledge status</b>
                            <span>Your Chatbro has processed {formatNumber(processedSources)} sources successfully, but there {sourcesToProcess - maxSources > 1 ? `are` : `is`} {formatNumber(sourcesToProcess - maxSources)} unprocessed source{sourcesToProcess - maxSources > 1 && `s`} due to your actual subscription plan.</span>
                        </div>
                        <div className="shrink-0 flex items-center gap-4">
                            <div className="shrink-0 flex flex-col items-center justify-center pr-2">
                                <Button 
                                    onClick={() => setIsOpenPricingModal(true)} 
                                    icon="CustomRocketLaunch" 
                                    color="dark"
                                >
                                    Upgrade
                                </Button>
                            </div>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }
    
    // Errors
    if (errorSources > 0) {
        return (
            <div className='relative'>
                <Alert
                    isLight
                    color='warning'
                    borderWidth={0}
                    className='shadow-3d-warning !flex-nowrap'
                >
                    <div className="flex items-start gap-4 w-full">
                        <div className="shrink-0">
                            <Icon 
                                color="warning"
                                size="4x"
                                icon="Error"
                            />
                        </div>
                        <div className="flex-1 w-full">
                            <b className="block">Knowledge status</b>
                            <span>Your Chatbro has processed {formatNumber(processedSources)} sources successfully, but there {errorSources > 1 ? `are` : `is`} {formatNumber(errorSources)} error{errorSources > 1 && `s`}:</span>
                            <br />
                            <span>
                                <ul>
                                    {sources?.filter((item:any) => item?.status == 'error')?.map((item:any, key:number) => (
                                        <li>- {item?.label}: <span className="font-semibold">{item?.status_message}</span></li>
                                    ))}
                                </ul>
                            </span>
                            <br />
                            You can retry by clicking on the "Retry All" button on the right.
                        </div>
                        <div className="shrink-0 flex items-center gap-4">
                            <div className="shrink-0 flex flex-col items-center justify-center pr-2">
                                <Button 
                                    isDisable={isLoadingRetryAll} 
                                    onClick={handleRetryAll} 
                                    icon={isLoadingRetryAll ? undefined : "Replay"} 
                                    color="warning"
                                >
                                    {isLoadingRetryAll && <Spinner isSmall className="mr-2"/>}
                                    Retry All
                                </Button>
                            </div>
                            <div className="shrink-0 flex flex-col items-center justify-center pr-2">
                                <h3 className="font-bold text-2xl">{formatNumber(errorSources)}</h3>
                                <small className="text-center">Error{errorSources > 1 && `s`}</small>
                            </div>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }

    // Pending
    if (processedSources < sourcesToProcess) {
        return (
            <div className='relative'>
                <Alert
                    isLight
                    color='info'
                    borderWidth={0}
                    className='shadow-3d-info !flex-nowrap'
                >
                    <div className="flex items-center gap-4 w-full">
                        <div className="shrink-0 pl-2">
                            <Spinner />
                        </div>
                        <div className="flex-1 w-full">
                            <b className="block">Knowledge status</b>
                            <span>Your Chatbro is actually learning from your data sources. This may take a few minutes.</span>
                        </div>
                        <div className="shrink-0 flex flex-col items-center justify-center pr-2">
                            <h3 className="font-bold text-2xl">{formatNumber(processedSources)} / {formatNumber(sourcesToProcess)}</h3>
                            <small className="text-center">Processed</small>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }
}

export default LearningAlert;