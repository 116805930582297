import React, { FC } from "react";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../components/bootstrap/Card";
import Badge from "../../../components/bootstrap/Badge";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { IconProps } from "react-toastify";
import Select from "../../../components/bootstrap/forms/Select";
import { goalOptions, languageOptions, toneOptions } from "./NewChatbotModal";
import Alert from "../../../components/bootstrap/Alert";

interface ICardProps {
    formik: any,
    className?: string
}

const CardGoal: FC<ICardProps> = ({
    formik, 
    className 
}) => {

    if (!formik) return <></>;

    return (
        <Card className={className}>
            <CardHeader>
                <CardLabel icon='SupportAgent' iconColor='primary'>
                    <CardTitle>Chatbro Goal</CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody className='pt-0 z-[0] pb-0'>
                <div className='row gap-3'>
                    <div className='col-12'>
                        <FormGroup
                            id='type'
                            label='Goal'
                            isFloating>
                            <Select
                                ariaLabel='Goal'
                                placeholder='Goal'
                                onChange={formik.handleChange}
                                value={formik.values.type}
                                list={goalOptions}
                            />
                        </FormGroup>
                    </div>
                    {formik?.values?.type &&
                        <div className="col-12">
                            <Alert
                                color="primary"
                                isLight
                                className="shadow-3d-primary !border-0 !block"
                            >
                                <b>{goalOptions?.filter((e:any) => e.value == formik?.values?.type)?.[0]?.text}</b>: {goalOptions?.filter((e:any) => e.value == formik?.values?.type)?.[0]?.description}
                            </Alert>
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    );
}

export default CardGoal;