import React, { useContext } from 'react';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import { SessionContext } from '../../../contexts/SessionContext';

const WelcomeMessage = () => {

	const { user } = useContext<any>(SessionContext);

	return (
		<div className='relative'>
			<Alert
				icon='Verified'
				isLight
				color='primary'
				borderWidth={0}
				className='shadow-3d-primary'
				isDismissible>
				<AlertHeading tag='h2' className='h4'>
					Welcome, {user?.name?.split(" ")?.[0]}! 🎉
				</AlertHeading>
				<span>Your AI-powered growth journey starts here!</span>
			</Alert>
		</div>
	);
};

export default WelcomeMessage;
